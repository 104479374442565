import React, { Component } from 'react'

import BaseLayout from '../components/BaseLayout'
import HeaderTitle from '../components/HeaderTitle'
import Table from '../components/Table'
import Graph from '../components/Graph'
import CardText from '../components/CardText'
import DatePicker, { setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { getDatabase, ref, onValue } from "firebase/database";

import { getMachines } from '../utils/DBMachineManagement'
import { getCocktails } from '../utils/DBCocktailManagement'
import { getBottles } from '../utils/DBBottleManagement'

class DashboardPage extends Component {

  constructor(){
    super();
    this.state = {
      machine_selected_id: sessionStorage.getItem('machine_selected_id'),
      allMachines: {},
      allBottles: {},
      allCocktails: {},
      loading: true,
      date_from: sessionStorage.getItem('date_from'),
      date_to: sessionStorage.getItem('date_to'),
      bottlesByMachine: undefined,
      metricsByMachine: undefined,
      lastDrink: "-",
      moreSolicited: "-",
      quantityDrinkServed: "0",
      timePour: "0",
      timePourFromStart: "0",
      iceAverage: "0",
      drinkAverage: "0",
    }
  }

  componentDidMount() {
    this.setDateInitial();
    this.fetchData();
  }

  setDateInitial = () => {
    if (this.state.date_from == null || this.state.date_from == undefined) {
      let to = new Date();
      let fromTime = to.getTime() - (24*3600*1000);
      let from = new Date(fromTime);
      this.setState({ date_to: to, date_from: from}); 
      sessionStorage.setItem('date_to', to);
      sessionStorage.setItem('date_from', from);
    }
  }

  fetchData = () => {
    let countLoadItems = 3;
    // Fetch all machines
    getMachines(machines => {
      countLoadItems -= 1;
      // Si no tengo seleccionada por defecto una machine. Selecciono la primera.
      if (this.state.machine_selected_id == null || this.state.machine_selected_id == undefined) {
        let firstMachine = Object.keys(machines)[0];
        this.setState({ allMachines: machines, machine_selected_id: firstMachine }); 
        sessionStorage.setItem('machine_selected_id', firstMachine);
        if (countLoadItems == 0) {
          this.setRealtimeListener();
        }
      } else {
        this.setState({ allMachines: machines, loading: countLoadItems != 0 }); 
      }
    });
    // Fetch all bottles
    getBottles(bottles => {
      countLoadItems -= 1;
      this.setState({ allBottles: bottles, loading: countLoadItems != 0 });
      if (countLoadItems == 0) {
        this.setRealtimeListener();
      }
    });
    // Fetch all cocktails
    getCocktails(cocktails => {
      countLoadItems -= 1;
      this.setState({ allCocktails: cocktails, loading: countLoadItems != 0 });
      if (countLoadItems == 0) {
        this.setRealtimeListener();
      }
    });
  }

  setRealtimeListener = () => {
    if (this.state.machine_selected_id != undefined) {
      const db = getDatabase();
      const bottlesRef = ref(db, 'machines/' + this.state.machine_selected_id + '/bottles');
      onValue(bottlesRef, (bottles) => {
        this.setState({ bottlesByMachine: bottles.val() });
      });

      const metricsRef = ref(db, 'machines/' + this.state.machine_selected_id + '/metrics');
      onValue(metricsRef, (metrics) => {
        this.setState({ metricsByMachine: metrics.val() });
        this.updateMetrics(metrics.val(), this.state.date_from, this.state.date_to);
      });
    }
  }

  renderGraph = () => {
    if (this.state.bottlesByMachine != undefined) {
      var colorBottles = ['rgba(0,10,220,0.5)', 'rgba(220,0,10,0.5)','rgba(0,10,220,0.5)', 'rgba(220,0,10,0.5)','rgba(0,10,220,0.5)', 'rgba(220,0,10,0.5)']
      var lenght = Object.keys(this.state.bottlesByMachine).length;
      
      colorBottles = colorBottles.splice(0, lenght);

      var cantBottles = [];
      var availableBottles = [];
          
      if (lenght == 0){
        return (<div style={{width: '100%', height: '100%', textAlign: 'center', transform: 'translateY(45%)'}}>
          <p>No hay botellas cargadas</p>
        </div>);
      }
      
      for (const [_, item] of Object.entries(this.state.bottlesByMachine)) {
        cantBottles[item.position - 1] = this.state.allBottles[item.bottle_id];
        availableBottles[item.position - 1] = item.quantity;
      }

      this.state.cantBottles = cantBottles;
      this.state.availableBottles = availableBottles;

      return(
          <Graph cantBottles={ this.state.cantBottles } availableBottles={ this.state.availableBottles } colorBottles={ colorBottles }/>
      );  
    }
  }
  
  onClickSelectMachine = (select) => {
    sessionStorage.setItem('machine_selected_id', select.target.value);
    this.state.machine_selected_id = select.target.value;
    this.setRealtimeListener();
  }
  
  renderSelect = () => {
    var list = [];
    for (const [_, item] of Object.entries(this.state.allMachines))
        list.push(<option selected={ item.id == this.state.machine_selected_id } value={ item.id }> { item.name } </option>);
    return(list);
  }

  // setTableCocktails = () => {
  //   var items = [["Trago", "Día", "Hora"]];
  //   var date, day, hour;
  //   var quantityDrinkServed = 0;
  //   var timePour = 0;
  //   var timePourFromStart = 0;
  //   var iceAverage = 0;
  //   var drinkAverage = 0;
  //   var lastDrink = "-";
  //   for (const [key, item] of Object.entries(this.state.metrics_machine)) {
  //     var nameCocktail;
  //     getNameCocktail((item.cocktail), (name) => {
  //       nameCocktail = name;
  //     });
  //     if ((key >= this.state.date_from) && (key <= this.state.date_to)){
  //       date = parseInt(key);
  //       date = new Date(date);
  //       day = date.getDate()+
  //         "/"+(date.getMonth()+1)+
  //         "/"+date.getFullYear();
  //       hour = (date.getHours()).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})+
  //       ":"+(date.getMinutes()).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})+
  //       ":"+(date.getSeconds()).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
  //       items.push([ 
  //         nameCocktail,
  //         day,
  //         hour 
  //       ]);
  //       quantityDrinkServed++;
  //       timePourFromStart += item.timeFromStart;
  //       timePour += item.timePouredCocktail;
  //       iceAverage += item.iceWeight;
  //       drinkAverage += item.drinkWeight;
  //     }
  //   }
  //   if (quantityDrinkServed > 0){
  //     timePourFromStart = (timePourFromStart/quantityDrinkServed)/1000 >> 0;
  //     timePour = (timePour/quantityDrinkServed)/1000 >> 0;
  //     iceAverage = (iceAverage/quantityDrinkServed) >> 0;
  //     drinkAverage = (drinkAverage/quantityDrinkServed) >> 0;
  //     lastDrink = items.slice(-1)[0][0];
  //   }
  //   this.setState({
  //     lastDrink: lastDrink, 
  //     quantityDrinkServed:quantityDrinkServed, 
  //     tableCocktails:items, 
  //     timePour:timePour, 
  //     timePourFromStart:timePourFromStart, 
  //     iceAverage: iceAverage,
  //     drinkAverage: drinkAverage,
  //   });
  // }

  

  renderTableCocktails = () => {    
    var items = [["Trago", "Día", "Hora"]];
    var date, day, hour;
    if (this.state.allCocktails != undefined && this.state.allCocktails != null) {
      for (const key in this.state.metricsByMachine) {
        const metric = this.state.metricsByMachine[key];
        if (new Date(metric.date) >= new Date(this.state.date_from) && new Date(metric.date) <= new Date(this.state.date_to)) {
          date = new Date(metric.date);
          day = date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear();
          hour = (date.getHours()).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})+
          ":"+(date.getMinutes()).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})+
          ":"+(date.getSeconds()).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});

          if (this.state.allCocktails.hasOwnProperty(metric.cocktail)) {            
            items.push([ 
              this.state.allCocktails[metric.cocktail].name,
              day,
              hour 
            ]);
          }
        }
      }
    }

    return(
      <div>
        <Table 
          id="1" 
          title="Tragos servidos" 
          count_rows="3" 
          width_sizes={ ['50%', '25%','25%'] } 
          items={ items } 
          loading={this.state.loading} 
          align={['left', 'left','right']}/>
      </div>
    )
  }

  renderTableCocktails2 = () => {
    var items = [["Trago", "Cantidad"]];
    var ponderaciones = new Map();
    if (this.state.allCocktails != undefined && this.state.allCocktails != null) {
      for (const key in this.state.metricsByMachine) {
        const metric = this.state.metricsByMachine[key];
        if (new Date(metric.date) >= new Date(this.state.date_from) && new Date(metric.date) <= new Date(this.state.date_to)) {
          if (ponderaciones.has(metric.cocktail)){
            ponderaciones.set(metric.cocktail, ponderaciones.get(metric.cocktail)+1);
          } else {
            ponderaciones.set(metric.cocktail, 1);
          }
        }
      }
      const ponderacionesSort = new Map([...ponderaciones.entries()].sort((a, b) => b[1] - a[1]));
      for(let [cocktailId, quantity] of ponderacionesSort) {
        if (this.state.allCocktails.hasOwnProperty(cocktailId)) {            
          items.push([ this.state.allCocktails[cocktailId].name, quantity ]);
        }
      }
      if (items.length > 1 && items[1][0] != this.state.moreSolicited) {
        this.setState({moreSolicited: items[1][0]});
      }
    }

    return (
      <div>
        <Table 
          id="2" 
          title="Ranking de Tragos" 
          count_rows="3" 
          width_sizes={ ['50%', '50%'] } 
          items={ items } 
          loading={this.state.loading} 
          align={['left', 'right']}/>
      </div>
    );
  }

  setSelectDateFrom = (date) => {
    if (new Date(date) > new Date(this.state.date_to)){
      let from = new Date(date);
      let toTime = from.getTime();
      let to = new Date(toTime);
      from.setHours(0, 0, 0, 0);
      to.setHours(23, 59, 59, 0);
      this.setState({ date_to: to, date_from: from}); 
      sessionStorage.setItem('date_to', to);
      sessionStorage.setItem('date_from', from);
      this.updateMetrics(this.state.metricsByMachine, from, to);
    } else {
      let newDate = new Date(date).setHours(0, 0, 0, 0);
      sessionStorage.setItem('date_from', new Date(newDate));
      this.setState({ date_from: new Date(newDate) }); 
      this.updateMetrics(this.state.metricsByMachine, date, this.state.date_to);
    }
  }

  setSelectDateTo = (date) => {
    if (new Date(this.state.date_from) > new Date(date)){
      let to = new Date(date);
      let fromTime = to.getTime();
      let from = new Date(fromTime);
      from.setHours(0, 0, 0, 0);
      to.setHours(23, 59, 59, 0);
      this.setState({ date_to: to, date_from: from}); 
      sessionStorage.setItem('date_to', to);
      sessionStorage.setItem('date_from', from);
      this.updateMetrics(this.state.metricsByMachine, from, to);
    } else {
      let newDate = new Date(date).setHours(23, 59, 59, 0);
      sessionStorage.setItem('date_to', new Date(newDate));
      this.setState({ date_to: new Date(newDate) }); 
      this.updateMetrics(this.state.metricsByMachine, this.state.date_from, date);
    }
  }

  renderSelectDateFrom = () => {
    var startDate = new Date(this.state.date_from);
    return (
      <DatePicker className="datePicker" dateFormat="dd/MM/yyyy" selected={ startDate } onChange={(date) => this.setSelectDateFrom(date)} />
    );
  }

  renderSelectDateTo = () => {
    var startDate = new Date(this.state.date_to);
    return (
      <DatePicker className="datePicker" dateFormat="dd/MM/yyyy" selected={ startDate } onChange={(date) => this.setSelectDateTo(date)}/>
    );
  }

  updateMetrics = (metrics, from, to) => {
    let lastDrink = "-";
    let moreSolicited = "-";
    let quantityPoured = 0;
    let iceAverage = 0;
    let drinkAverage = 0;
    let timePour = 0;
    let timePourFromStart = 0;

    if (metrics != undefined) {
      let latestTimestamp = 0;
      let latestCocktailId = 0;

      for (const key in metrics) {
        const metric = metrics[key];
        if (new Date(metric.date) >= new Date(from) && new Date(metric.date) <= new Date(to)) {
          // Se usa para el ultimo servido
          if (metric.date > latestTimestamp) {
            latestTimestamp = metric.date;
            latestCocktailId = metric.cocktail;
          }
          // Cuento el trago
          quantityPoured ++;
          //Sumo para el promedio de hielo
          iceAverage += metric.iceWeight;
          //Sumo para el promedio de bebida
          drinkAverage += metric.drinkWeight;
          //Sumo el tiempo acumulado desde el inicio
          timePourFromStart += metric.timeFromStart;
          //Sumo el tiempo acumulado en servir
          timePour += metric.timePouredCocktail;
        }
      }

      if (Object.keys(this.state.allCocktails).length > 0) {
        if (this.state.allCocktails.hasOwnProperty(latestCocktailId)) {
          lastDrink = this.state.allCocktails[latestCocktailId].name;
        }
      }
    }

    this.setState({
      lastDrink: lastDrink,
      moreSolicited: moreSolicited,
      quantityDrinkServed: quantityPoured,
      iceAverage: (quantityPoured == 0) ? 0 : (iceAverage / quantityPoured).toFixed(1),
      drinkAverage: (quantityPoured == 0) ? 0 : (drinkAverage / quantityPoured).toFixed(1),
      timePour: (quantityPoured == 0) ? 0 : (timePour / (quantityPoured * 1000)).toFixed(1),
      timePourFromStart: (quantityPoured == 0) ? 0 : (timePourFromStart / (quantityPoured * 1000)).toFixed(1),
    });
  }

  renderLastDrinkServed = () => {
    return (
      <CardText name="Último trago servido" value={ this.state.lastDrink } color="#FFF"/>
    );
  }

  renderMoreSolicited = () => {
    return (
      <CardText name="Trago más solicitado" value={ this.state.moreSolicited } color="#FFF"/>
    );
  }

  renderQuantityDrinksServed = () => {
    return (
      <CardText name="Cantidad de tragos servidos" value={this.state.quantityDrinkServed} color="#FFF"/>
    );
  }

  renderAveragePourDrinkDuration = () => {
    return (
      <CardText name="Tiempo promedio al servir" value={this.state.timePour + " segundos"} color="#FFF"/>
    );
  }

  renderAverageUseDrinkDuration = () => {
    return (
      <CardText name="Tiempo promedio de uso" value={this.state.timePourFromStart + " segundos"} color="#FFF"/>
    );
  }

  renderAverageUseDrinkDuration = () => {
    return (
      <CardText name="Tiempo promedio de uso" value={this.state.timePourFromStart + " segundos"} color="#FFF"/>
    );
  }

  renderAverageIce = () => {
    return (
      <CardText name="Cantidad de hielo promedio" value={this.state.iceAverage + " grs"} color="#FFF"/>
    );
  }

  renderAverageDrink = () => {
    return (
      <CardText name="Cantidad de bebida promedio" value={this.state.drinkAverage + " grs"} color="#FFF"/>
    );
  }

  render() {
      return(
        <BaseLayout active_item_sidebar={1}>
          <div className="container-page">
            <HeaderTitle subtitle="Dashboard" title="Bloop Machine"/>
            <div className='container-custom'>
              <div class="row">
                <div className="col-sm-12 col-md-12 col-lg-6">
                  { this.renderGraph()}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div class="row">
                    <div className="col-sm-6 col-md-6 col-lg-4">
                      <select onChange={(e) => this.onClickSelectMachine(e)} id="position_add" className="input-custom" style={{ width: "100%", marginBottom: '1rem' }}>
                        { this.renderSelect()}
                      </select>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4" style={{marginBottom: '15px'}}>
                      { this.renderSelectDateFrom() }
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4" style={{marginBottom: '15px'}}>
                      { this.renderSelectDateTo() }
                    </div>
                  </div>
                  <div class="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      {this.renderLastDrinkServed()}
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      {this.renderMoreSolicited()}
                    </div>
                  </div>
                  <div class="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      {this.renderAveragePourDrinkDuration()}
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      {this.renderAverageUseDrinkDuration()}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div className="col-sm-4 col-md-4 col-lg-4">
                  { this.renderQuantityDrinksServed() }
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4">
                { this.renderAverageIce() }
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4">
                { this.renderAverageDrink() }
                </div>
              </div>
              <div class="row">
                <div className="col-sm-12 col-md-12 col-lg-6">
                  {this.renderTableCocktails()}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  {this.renderTableCocktails2()}
                </div>
              </div>
            </div>
          </div>
        </BaseLayout>
      );
    }
}

export default DashboardPage;