import React, { Component } from 'react';

import CocktailPage from '../pages/CocktailPage'

class AddCocktailsPage extends Component {

	render() {
    return(
      <CocktailPage isEditMode={false} />
    );  
  }

}

export default AddCocktailsPage;