import React, { Component } from 'react';

import BaseLayout from '../components/BaseLayout'
import HeaderTitle from '../components/HeaderTitle'
import Table from '../components/Table'
import { NotificationManager } from 'react-notifications';

import { MultiSelect } from "react-multi-select-component";
import { getBottles } from '../utils/DBBottleManagement'
import { MdMode, MdDelete, MdSave } from "react-icons/md";

import { updateCocktail, removeCocktail, pushCocktail } from '../utils/DBCocktailManagement'
import { getStorage, ref, uploadBytes, deleteObject } from "firebase/storage";

class CocktailPage extends Component {

  constructor(){
    super();
    this.state = {
      id: 0,
      page_loading: false,
      bottles: [],
      bottles_selected: [],
      drinks: [],
      drinksWOA: [],
      bottle_to_remove: '',
      isBottleWOA: false,
      bottle_to_update: '',
      fileToUpload: undefined,
      preview: "./images/empty-photo.png"
    }
  }

  componentDidMount() {
    this.fetchBottles();
    if (this.props.isEditMode === true) {
      this.setState({ preview: this.props.image });
    }
  }

  fetchBottles = () => {
    getBottles(data => {
      this.setState({ bottles: data });
      if (this.props.isEditMode === true) {
        this.loadCurrentDrinks();
      }
    });
  }

  loadCurrentDrinks = () => {
    // Generate cocktail drinks
    var drinks = [];
    if (this.props.drinks !== undefined && this.props.drinks.length > 0) {
      this.props.drinks.forEach(drink => {
        var bottleDrinkStr = drink.bottles.split("-");
        var bottleDrink = [];
        bottleDrinkStr.forEach(bottleStr => {
          bottleDrink.push(parseInt(bottleStr))
        });

        drinks.push({
          "default": drink.default,
          "bottles": bottleDrink,
          "id": drink.id,
          "level1": drink.level1,
          "level2": drink.level2,
          "level3": drink.level3,
          "position": drink.position,
          "unit": drink.unit,
        });
      });
    }

    // Generate cocktail drinksWOA
    var drinksWOA = [];
    if (this.props.drinksWOA !== undefined && this.props.drinksWOA.length > 0) {
      this.props.drinksWOA.forEach(drink => {
        var bottleDrinkStr = drink.bottles.split("-");
        var bottleDrink = [];
        bottleDrinkStr.forEach(bottleStr => {
          bottleDrink.push(parseInt(bottleStr))
        });

        drinksWOA.push({
          "default": drink.default,
          "bottles": bottleDrink,
          "id": drink.id,
          "quantity": drink.quantity,
          "position": drink.position,
          "unit": drink.unit,
        });
      });
    }

    // Save data in state
    this.setState({
      bottles_selected: [],
      id: this.props.id,
      drinks: drinks,
      drinksWOA: drinksWOA,
      bottle_to_remove: '',
      bottle_to_update: ''
    });
    document.getElementById("name").value = this.props.name;
    if (this.props.ingredients != undefined && this.props.ingredients != "") {
      document.getElementById("ingredients").value = this.props.ingredients; 
    }
    if (this.props.ingredientsWOA != undefined && this.props.ingredientsWOA != "") {
      document.getElementById("ingredients-woa").value = this.props.ingredientsWOA; 
    }
  }

  addBottle = () => {
    var quantity1 = document.getElementById('quantity1_add').value;
    var quantity2 = document.getElementById('quantity2_add').value;
    var quantity3 = document.getElementById('quantity3_add').value;
    
    var hasError = false;
    if (quantity1 === undefined || quantity1 === "") {
      document.getElementById('quantity1_add').classList.add("input-error");
      hasError = true;
    } else {
      document.getElementById('quantity1_add').classList.remove("input-error");
    }
    if (quantity2 === undefined || quantity2 === "") {
      document.getElementById('quantity2_add').classList.add("input-error");
      hasError = true;
    } else {
      document.getElementById('quantity2_add').classList.remove("input-error");
    }
    if (quantity3 === undefined || quantity3 === "") {
      document.getElementById('quantity3_add').classList.add("input-error");
      hasError = true;
    } else {
      document.getElementById('quantity3_add').classList.remove("input-error");
    }
    if (this.state.bottles_selected.length === 0) {
      document.getElementById('default-bottle_add').classList.add("input-error");
      document.getElementsByClassName("dropdown-container")[0].classList.add("input-error");
      hasError = true;
    } else {
      document.getElementsByClassName("dropdown-container")[0].classList.remove("input-error");
      document.getElementById('default-bottle_add').classList.remove("input-error");
    }
    if (hasError) { return; }

    document.getElementById("button-add-cancel").click();

    var select_unit = document.getElementById("unit_add");
    var unit = select_unit.options[select_unit.selectedIndex].value;
    var select_position = document.getElementById("position_add");
    var position = select_position.options[select_position.selectedIndex].value;
    
    var select_default_bottle = document.getElementById("default-bottle_add");
    var default_bottle = select_default_bottle.options[select_default_bottle.selectedIndex].value;
    
    var newBottles = [];
    this.state.bottles_selected.forEach(bottle => {
      newBottles.push(parseInt(bottle.value));
    });
    var object = {
      "id": new Date().getTime(),
      "unit": unit,
      "bottles": newBottles,
      "level1": parseInt(quantity1),
      "level2": parseInt(quantity2),
      "level3": parseInt(quantity3),
      "position": parseInt(position),
      "default": parseInt(default_bottle),
    }

    var drinks = this.state.drinks;
    drinks.push(object);
    this.setState({ 
      drinks: drinks,
      bottles_selected: [] 
    });
  }

  addBottleWOA = () => {
    var quantity = document.getElementById('quantity_woa_add').value;
    
    var hasError = false;
    if (quantity === undefined || quantity === "") {
      document.getElementById('quantity_woa_add').classList.add("input-error");
      hasError = true;
    } else {
      document.getElementById('quantity_woa_add').classList.remove("input-error");
    }
    if (this.state.bottles_selected.length === 0) {
      document.getElementById('default-bottle_add_woa').classList.add("input-error");
      document.getElementsByClassName("dropdown-container")[0].classList.add("input-error");
      hasError = true;
    } else {
      document.getElementsByClassName("dropdown-container")[0].classList.remove("input-error");
      document.getElementById('default-bottle_add_woa').classList.remove("input-error");
    }
    if (hasError) { return; }

    document.getElementById("button-add-cancel-woa").click();

    var select_unit = document.getElementById("unit_add_woa");
    var unit = select_unit.options[select_unit.selectedIndex].value;
    var select_position = document.getElementById("position_add_woa");
    var position = select_position.options[select_position.selectedIndex].value;
    
    var select_default_bottle = document.getElementById("default-bottle_add_woa");
    var default_bottle = select_default_bottle.options[select_default_bottle.selectedIndex].value;
    
    var newBottles = [];
    this.state.bottles_selected.forEach(bottle => {
      newBottles.push(parseInt(bottle.value));
    });
    var object = {
      "id": new Date().getTime(),
      "unit": unit,
      "bottles": newBottles,
      "quantity": parseInt(quantity),
      "position": parseInt(position),
      "default": parseInt(default_bottle),
    }

    var drinks = this.state.drinksWOA;
    drinks.push(object);
    this.setState({ 
      drinksWOA: drinks,
      bottles_selected: []
    });
  }

  onClickSelectBottle = (item) => {    
    this.setState({ bottles_selected: item });
  }

  renderDefaultBottle = () => {
    var list = [];
    this.state.bottles_selected.forEach( item => {
        list.push(<option value={ item.value }> { item.label } </option>);
    });
    return (list);
  }

  auxiliaryMultiSelect = () => {
    var result = [];
    for (const [key, item] of Object.entries(this.state.bottles)) {
      result.push({
        label: item,
        value: key
      });
    };
    return result;
  }

  auxiliaryMultiSelectByList = (bottlesIdList) => {
    var result = [];
    bottlesIdList.forEach( bottleId => {
      result.push({
        label: this.state.bottles[bottleId],
        value: String(bottleId)
      });
    });
    return result;
  }

  renderAddCocktailModal = () => {
    return(
      <div id="modal-add-cocktail" class="modal fade" role="dialog" style={{marginTop: "10%"}}>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Agregar bebida</h4>
            </div>
            <div class="modal-body">
              <p style={{marginBottom: '0'}}>Seleccione la bebida</p>
              <MultiSelect
                hasSelectAll={false}
                value={ this.state.bottles_selected }
                options={ this.auxiliaryMultiSelect() }
                onChange={ (item) => this.onClickSelectBottle(item) } />
              <div class="row" style={{ margin: '0', marginTop:'1rem' }}>
                <div class="column" style={{width: '50%', paddingRight: '7px'}}>
                  <p style={{marginBottom: '0'}}>Posición</p>
                  <select id="position_add" className="input-custom" style={{ width: "100%", marginBottom: '1rem' }}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
                <div class="column" style={{width: '50%', paddingLeft: '7px'}}>
                  <p style={{marginBottom: '0'}}>Default</p>
                  <select id="default-bottle_add" className="input-custom" style={{ width: "100%", marginBottom: '1rem' }}>
                    { this.renderDefaultBottle() }
                  </select>
                </div>
              </div>
              <div class="row" style={{ margin: '0', marginTop:'1rem' }}>
                <div class="column" style={{width: '25%', paddingRight: '7px'}}>
                  <p style={{marginBottom: '0'}}>Nivel 1</p>
                  <input id="quantity1_add" type="number" class="form-control" style={{marginBottom: '1rem'}}/>
                </div>
                <div class="column" style={{width: '25%', paddingRight: '7px', paddingLeft: '7px'}}>
                  <p style={{marginBottom: '0'}}>Nivel 2</p>
                  <input id="quantity2_add" type="number" class="form-control" style={{marginBottom: '1rem'}}/>
                </div>
                <div class="column" style={{width: '25%', paddingRight: '7px', paddingLeft: '7px'}}>
                  <p style={{marginBottom: '0'}}>Nivel 3</p>
                  <input id="quantity3_add" type="number" class="form-control" style={{marginBottom: '1rem'}}/>
                </div>
                <div class="column" style={{width: '25%', paddingLeft: '7px'}}>
                  <p style={{marginBottom: '0'}}>Unidad</p>
                  <select id="unit_add" className="input-custom" style={{ width: "100%", marginBottom: '1rem' }}>
                    <option value="M">ml</option>
                    <option value="%">%</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button id="button-add-cancel" type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
              <button onClick={ () => this.addBottle() } type="button" class="btn btn-success">Agregar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAddCocktailModalWOA = () => {
    return(
      <div id="modal-add-cocktail-woa" class="modal fade" role="dialog" style={{marginTop: "10%"}}>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Agregar bebida</h4>
            </div>
            <div class="modal-body">
              <p style={{marginBottom: '0'}}>Seleccione la bebida</p>
              <MultiSelect
                hasSelectAll={false}
                value={ this.state.bottles_selected }
                options={ this.auxiliaryMultiSelect() }
                onChange={ (item) => this.onClickSelectBottle(item) } />
              <div class="row" style={{ margin: '0', marginTop:'1rem' }}>
                <div class="column" style={{width: '50%', paddingRight: '7px'}}>
                  <p style={{marginBottom: '0'}}>Posición</p>
                  <select id="position_add_woa" className="input-custom" style={{ width: "100%", marginBottom: '1rem' }}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
                <div class="column" style={{width: '50%', paddingLeft: '7px'}}>
                  <p style={{marginBottom: '0'}}>Default</p>
                  <select id="default-bottle_add_woa" className="input-custom" style={{ width: "100%", marginBottom: '1rem' }}>
                    { this.renderDefaultBottle() }
                  </select>
                </div>
              </div>
              <div class="row" style={{ margin: '0', marginTop:'1rem' }}>
                <div class="column" style={{width: '75%', paddingRight: '7px'}}>
                  <p style={{marginBottom: '0'}}>Cantidad</p>
                  <input id="quantity_woa_add" type="number" class="form-control" style={{marginBottom: '1rem'}}/>
                </div>
                <div class="column" style={{width: '25%', paddingLeft: '7px'}}>
                  <p style={{marginBottom: '0'}}>Unidad</p>
                  <select id="unit_add_woa" className="input-custom" style={{ width: "100%", marginBottom: '1rem' }}>
                    <option value="M">ml</option>
                    <option value="%">%</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button id="button-add-cancel-woa" type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
              <button onClick={ () => this.addBottleWOA() } type="button" class="btn btn-success">Agregar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderEditCocktailModalWOA = () => {
    return(
      <div id="modal-edit-cocktail-woa" class="modal fade" role="dialog" style={{marginTop: "10%"}}>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Editar bebida</h4>
            </div>
            <div class="modal-body">
              <p style={{marginBottom: '0'}}>Seleccione la bebida</p>
              <MultiSelect
                hasSelectAll={false}
                value={ this.state.bottles_selected }
                options={ this.auxiliaryMultiSelect() }
                onChange={ (item) => this.onClickSelectBottle(item) } />  
              <div class="row" style={{ margin: '0', marginTop:'1rem' }}>
                <div class="column" style={{width: '50%', paddingRight: '7px'}}>
                  <p style={{marginBottom: '0'}}>Posición</p>
                  <select id="position_edit_woa" className="input-custom" style={{ width: "100%", marginBottom: '1rem' }}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
                <div class="column" style={{width: '50%', paddingLeft: '7px'}}>
                  <p style={{marginBottom: '0'}}>Default</p>
                  <select id="default-bottle_edit_woa" className="input-custom" style={{ width: "100%", marginBottom: '1rem' }}>
                    { this.renderDefaultBottle() }
                  </select>
                </div>
              </div>
              <div class="row" style={{ margin: '0', marginTop:'1rem' }}>
                <div class="column" style={{width: '75%', paddingRight: '7px'}}>
                  <p style={{marginBottom: '0'}}>Cantidad</p>
                  <input id="quantity_woa_edit" type="number" class="form-control" style={{marginBottom: '1rem'}}/>
                </div>
                <div class="column" style={{width: '25%', paddingLeft: '7px'}}>
                  <p style={{marginBottom: '0'}}>Unidad</p>
                  <select id="unit_edit_woa" className="input-custom" style={{ width: "100%", marginBottom: '1rem' }}>
                    <option value="M">ml</option>
                    <option value="%">%</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button id="button-edit-cancel" type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
              <button onClick={ () => this.editBottleWOA() } type="button" class="btn btn-success" data-dismiss="modal">Confirmar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderEditCocktailModal = () => {
    return(
      <div id="modal-edit-cocktail" class="modal fade" role="dialog" style={{marginTop: "10%"}}>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Editar bebida</h4>
            </div>
            <div class="modal-body">
              <p style={{marginBottom: '0'}}>Seleccione la bebida</p>
              <MultiSelect
                hasSelectAll={false}
                value={ this.state.bottles_selected }
                options={ this.auxiliaryMultiSelect() }
                onChange={ (item) => this.onClickSelectBottle(item) } />  
              <div class="row" style={{ margin: '0', marginTop:'1rem' }}>
                <div class="column" style={{width: '50%', paddingRight: '7px'}}>
                  <p style={{marginBottom: '0'}}>Posición</p>
                  <select id="position_edit" className="input-custom" style={{ width: "100%", marginBottom: '1rem' }}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
                <div class="column" style={{width: '50%', paddingLeft: '7px'}}>
                  <p style={{marginBottom: '0'}}>Default</p>
                  <select id="default-bottle_edit" className="input-custom" style={{ width: "100%", marginBottom: '1rem' }}>
                    { this.renderDefaultBottle() }
                  </select>
                </div>
              </div>
              <div class="row" style={{ margin: '0', marginTop:'1rem' }}>
                <div class="column" style={{width: '25%', paddingRight: '7px'}}>
                  <p style={{marginBottom: '0'}}>Nivel 1</p>
                  <input id="quantity1_edit" type="number" class="form-control" style={{marginBottom: '1rem'}}/>
                </div>
                <div class="column" style={{width: '25%', paddingRight: '7px', paddingLeft: '7px'}}>
                  <p style={{marginBottom: '0'}}>Nivel 2</p>
                  <input id="quantity2_edit" type="number" class="form-control" style={{marginBottom: '1rem'}}/>
                </div>
                <div class="column" style={{width: '25%', paddingRight: '7px', paddingLeft: '7px'}}>
                  <p style={{marginBottom: '0'}}>Nivel 3</p>
                  <input id="quantity3_edit" type="number" class="form-control" style={{marginBottom: '1rem'}}/>
                </div>
                <div class="column" style={{width: '25%', paddingLeft: '7px'}}>
                  <p style={{marginBottom: '0'}}>Unidad</p>
                  <select id="unit_edit" className="input-custom" style={{ width: "100%", marginBottom: '1rem' }}>
                    <option value="M">ml</option>
                    <option value="%">%</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button id="button-edit-cancel" type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
              <button onClick={ () => this.editBottle() } type="button" class="btn btn-success" data-dismiss="modal">Confirmar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  editBottle = () => {
    var quantity1 = document.getElementById('quantity1_edit').value;
    var quantity2 = document.getElementById('quantity2_edit').value;
    var quantity3 = document.getElementById('quantity3_edit').value;
    
    var hasError = false;
    if (quantity1 === undefined || quantity1 === "") {
      document.getElementById('quantity1_edit').classList.add("input-error");
      hasError = true;
    } else {
      document.getElementById('quantity1_edit').classList.remove("input-error");
    }
    if (quantity2 === undefined || quantity2 === "") {
      document.getElementById('quantity2_edit').classList.add("input-error");
      hasError = true;
    } else {
      document.getElementById('quantity2_edit').classList.remove("input-error");
    }
    if (quantity3 === undefined || quantity3 === "") {
      document.getElementById('quantity3_edit').classList.add("input-error");
      hasError = true;
    } else {
      document.getElementById('quantity3_edit').classList.remove("input-error");
    }
    if (this.state.bottles_selected.length === 0) {
      document.getElementById('default-bottle_edit').classList.add("input-error");
      document.getElementsByClassName("dropdown-container")[1].classList.add("input-error");
      hasError = true;
    } else {
      document.getElementsByClassName("dropdown-container")[1].classList.remove("input-error");
      document.getElementById('default-bottle_edit').classList.remove("input-error");
    }
    if (hasError) { return; }

    document.getElementById("button-edit-cancel").click();

    var itemSelected = this.state.bottle_to_update;
    var drinks = this.state.drinks;

    var newBottles = [];
    this.state.bottles_selected.forEach(bottle => {
      newBottles.push(parseInt(bottle.value));
    });
    drinks.forEach(item => {
      if (itemSelected === item.id) {
        var quantity1 = document.getElementById('quantity1_edit').value;
        var quantity2 = document.getElementById('quantity2_edit').value;
        var quantity3 = document.getElementById('quantity3_edit').value;
        if (this.state.bottles_selected.length === 0) {
          NotificationManager.error('Seleccione al menos una bebida.', 'Error');
          return;
        }

        var select_unit = document.getElementById("unit_edit");
        var unit = select_unit.options[select_unit.selectedIndex].value;
        var select_position = document.getElementById("position_edit");
        var position = select_position.options[select_position.selectedIndex].value;
        
        var select_default_bottle = document.getElementById("default-bottle_edit");
        var default_bottle = select_default_bottle.options[select_default_bottle.selectedIndex].value;

        item.unit = unit;
        item.bottles = newBottles;
        item.level1 = parseInt(quantity1);
        item.level2 = parseInt(quantity2);
        item.level3 = parseInt(quantity3);
        item.position = parseInt(position);
        item.default = parseInt(default_bottle);
      }
    })
    this.setState({ drinks: drinks });
  }

  editBottleWOA = () => {
    var quantity = document.getElementById('quantity_woa_edit').value;
    
    var hasError = false;
    if (quantity === undefined || quantity === "") {
      document.getElementById('quantity_woa_edit').classList.add("input-error");
      hasError = true;
    } else {
      document.getElementById('quantity_woa_edit').classList.remove("input-error");
    }
    
    if (this.state.bottles_selected.length === 0) {
      document.getElementById('default-bottle_edit_woa').classList.add("input-error");
      document.getElementsByClassName("dropdown-container")[1].classList.add("input-error");
      hasError = true;
    } else {
      document.getElementsByClassName("dropdown-container")[1].classList.remove("input-error");
      document.getElementById('default-bottle_edit_woa').classList.remove("input-error");
    }
    if (hasError) { return; }

    document.getElementById("button-edit-cancel").click();

    var itemSelected = this.state.bottle_to_update;
    var drinksWOA = this.state.drinksWOA;

    var newBottles = [];
    this.state.bottles_selected.forEach(bottle => {
      newBottles.push(parseInt(bottle.value));
    });

    drinksWOA.forEach(item => {
      if (itemSelected === item.id) {
        if (this.state.bottles_selected.length === 0) {
          NotificationManager.error('Seleccione al menos una bebida.', 'Error');
          return;
        }

        var select_unit = document.getElementById("unit_edit_woa");
        var unit = select_unit.options[select_unit.selectedIndex].value;
        var select_position = document.getElementById("position_edit_woa");
        var position = select_position.options[select_position.selectedIndex].value;
        
        var select_default_bottle = document.getElementById("default-bottle_edit_woa");
        var default_bottle = select_default_bottle.options[select_default_bottle.selectedIndex].value;

        item.unit = unit;
        item.bottles = newBottles;
        item.quantity = parseInt(quantity);
        item.position = parseInt(position);
        item.default = parseInt(default_bottle);
      }
    })
    this.setState({ drinksWOA: drinksWOA });
  }

  renderRemoveCocktailModal = () => {
    return(
      <div id="modal-remove" class="modal fade" role="dialog" style={{marginTop: "10%"}}>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Eliminar bebida</h4>
            </div>
            <div class="modal-body">
              <p style={{marginBottom: '0'}}>¿Estás seguro que deseas eliminar la bebida?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
              <button onClick={ () => this.deleteBottle() } type="button" class="btn btn-success" data-dismiss="modal">Confirmar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderForm = () => {
    return (
      <div class="card card-small" style={{marginBottom: '15px'}}>
        <div class="card-header">
          <h6 class="m-0">Datos generales</h6>
        </div>
        <ul class="list-group list-group-flush">
          <li class="p-3 list-group-item" style={{ borderBottomRightRadius: "0.625rem", borderBottomLeftRadius: "0.625rem" }}>
            <div class="row">
              <div class="col">
                <form class="">
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label for="name">Nombre</label>
                      <input id="name" type="name" placeholder="Nombre del trago" class="form-control" />
                    </div>
                    <div class="form-group col-md-12">
                      <label for="ingredients">Ingredientes</label>
                      <input id="ingredients" type="ingredients" placeholder="Ingrese los ingredientes separado por coma" class="form-control" />
                    </div>
                    <div class="form-group col-md-12">
                      <label for="ingredients-woa">Ingredientes (receta sin alcohol)</label>
                      <input id="ingredients-woa" type="ingredients" placeholder="Ingrese los ingredientes separado por coma" class="form-control" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
  }

  onClickAddBottle = () => {
    this.setState({ bottles_selected: [] });
    document.getElementById("quantity1_add").value = "";
    document.getElementById("quantity2_add").value = "";
    document.getElementById("quantity3_add").value = "";
    document.getElementById("position_add").value = "1";
    document.getElementById('quantity1_add').classList.remove("input-error");
    document.getElementById('quantity2_add').classList.remove("input-error");
    document.getElementById('quantity3_add').classList.remove("input-error");
    document.getElementsByClassName("dropdown-container")[0].classList.remove("input-error");
    document.getElementById('default-bottle_add').classList.remove("input-error");
    document.getElementById("unit_add").value = "M";
    document.getElementById("button-add-modal").click();
  }

  onClickAddBottleWOA = () => {    
    this.setState({ bottles_selected: [] });
    document.getElementById("quantity_woa_add").value = "";
    document.getElementById("position_add_woa").value = "1";
    document.getElementsByClassName("dropdown-container")[0].classList.remove("input-error");
    document.getElementById('default-bottle_add_woa').classList.remove("input-error");
    document.getElementById("unit_add_woa").value = "M";
    document.getElementById("button-add-modal-woa").click();
  }

  renderTable = () => {
    var allBottles = this.state.bottles;
    var items = [["Nombre", "Posición", "Nivel 1", "Nivel 2", "Nivel 3", "Unidad", "Acciones"]];
    this.state.drinks.forEach(item => {
      // Generate name
      var name = "";
      item.bottles.forEach(item => {
        name += allBottles[item] + ", ";
      })
      name = name.substring(0, name.length -2);
      //Append in list
      items.push([ 
        name, 
        item.position, 
        item.level1, 
        item.level2, 
        item.level3, 
        item.unit, 
        <div>
          <button onClick={() => this.handlerUpdateDataRow(item)} data-toggle="modal" data-target="#modal-edit-cocktail" class="button button-edit"><MdMode style={{color: "#fff"}}/></button>
          <button onClick={() => this.handlerRemoveRow(false, item)} data-toggle="modal" data-target="#modal-remove-bottle" class="button button-delete"><MdDelete style={{color: "#fff"}}/></button>
        </div>
       ]);
    });
    return (
      <Table id="1" title="Receta con alcohol" header_button={true} header_callback={() => this.onClickAddBottle() } orderBy={1}
            count_rows="7" width_sizes={ ['40%', '9%', '9%', '9%', '9%', '9%', '15%'] } items={ items } />
    );
  }

  renderTableWithOutAlcohol = () => {
    var allBottles = this.state.bottles;
    var items = [["Nombre", "Posición", "Cantidad", "Unidad", "Acciones"]];
    this.state.drinksWOA.forEach(item => {
      // Generate name
      var name = "";
      item.bottles.forEach(item => {
        name += allBottles[item] + ", ";
      })
      name = name.substring(0, name.length -2);
      //Append in list
      items.push([
        name,
        item.position,
        item.quantity,
        item.unit,
        <div>
          <button onClick={() => this.handlerUpdateWOADataRow(item)} data-toggle="modal" data-target="#modal-edit-cocktail-woa" class="button button-edit"><MdMode style={{color: "#fff"}}/></button>
          <button onClick={() => this.handlerRemoveRow(true, item)} data-toggle="modal" data-target="#modal-remove-bottle" class="button button-delete"><MdDelete style={{color: "#fff"}}/></button>
        </div>
      ]);
    });
    return (
      <Table id="2" title="Receta sin alcohol" header_button={true} 
          header_callback={() => this.onClickAddBottleWOA() } orderBy={1}
          count_rows="5" width_sizes={ ['40%', '15%', '15%', '15%', '15%'] } items={ items } />
    );
  }

  handlerUpdateDataRow = (item) => {
    this.setState({ 
      bottles_selected: this.auxiliaryMultiSelectByList(item.bottles),
      bottle_to_update: item.id 
    });
    document.getElementById("quantity1_edit").value = item.level1;
    document.getElementById("quantity2_edit").value = item.level2;
    document.getElementById("quantity3_edit").value = item.level3;
    document.getElementById("position_edit").value = item.position;
    document.getElementById("unit_edit").value = item.unit;    
    setTimeout(function() {
      document.getElementById("default-bottle_edit").value = String(item.default);
    }, 50);
    document.getElementById('quantity1_edit').classList.remove("input-error");
    document.getElementById('quantity2_edit').classList.remove("input-error");
    document.getElementById('quantity3_edit').classList.remove("input-error");
    document.getElementsByClassName("dropdown-container")[1].classList.remove("input-error");
    document.getElementById('default-bottle_edit').classList.remove("input-error");
  }

  handlerUpdateWOADataRow = (item) => {
    this.setState({ 
      bottles_selected: this.auxiliaryMultiSelectByList(item.bottles),
      bottle_to_update: item.id 
    });
    document.getElementById("quantity_woa_edit").value = item.quantity;
    document.getElementById("position_edit_woa").value = item.position;
    document.getElementById("unit_edit_woa").value = item.unit;
    setTimeout(function() {
      document.getElementById("default-bottle_edit_woa").value = String(item.default);
    }, 50);
    document.getElementById('quantity_woa_edit').classList.remove("input-error");
    document.getElementById('position_edit_woa').classList.remove("input-error");
    document.getElementById('unit_edit_woa').classList.remove("input-error");
    document.getElementById('default-bottle_edit_woa').classList.remove("input-error");
  }

  deleteBottle = () => {
    var isWOA = this.state.isBottleWOA;
    var id = this.state.bottle_to_remove;
    var new_drinks = [];

    var drinks = this.state.drinks;
    if (isWOA) {
      drinks = this.state.drinksWOA
    }
    drinks.forEach(item => {
      if (item.id !== id) {
        new_drinks.push(item);
      }
    });
    if (isWOA) {
      this.setState({ bottle_to_remove: "", drinksWOA: new_drinks, isWOA: false });
    } else {
      this.setState({ bottle_to_remove: "", drinks: new_drinks, isWOA: false });
    }    
  }

  handlerRemoveRow = (isWOA, item) => {
    this.setState({ 
      bottle_to_remove: item.id,
      isBottleWOA: isWOA
    });
  }

  handlerCheckPosition = (drinks) => {
    var position = [];
    drinks.forEach(item => {
      position.push(item.position);
    });
    var hasRepeatedItems = false;
    var hasContinuos = true;
    var auxPositions = [];
    for(var i=1; i<=position.length; i++) {
      if (!position.includes(i)) {
        hasContinuos = false;
      }
      if (auxPositions.includes(position[i-1])) {
        hasRepeatedItems = true;
      } else {
        auxPositions.push(position[i-1]);
      }
    }
    if (hasRepeatedItems || !hasContinuos) {
      return false;
    } else {
      return true;
    }
  }

  onSaveData = () => {
    // Check has image and name
    var name = document.getElementById("name");
    if (name.value === undefined || name.value === "") {
      name.classList.add("input-error");
      return;
    } else {
      name.classList.remove("input-error");
    }

    if ((this.state.drinks.length === 0) && (this.state.drinksWOA.length === 0)) {
      NotificationManager.error('Agregue al menos una bebida.', 'Error');
      return;
    }

    var ingredients = document.getElementById("ingredients");
    var ingredients_woa = document.getElementById("ingredients-woa");
    if (this.state.drinks.length > 0) {
      if (ingredients.value === undefined || ingredients.value === "") {
        ingredients.classList.add("input-error");
        return;
      } else {
        ingredients.classList.remove("input-error");
      }
    }
    if (this.state.drinksWOA.length > 0) {
      if (ingredients_woa.value === undefined || ingredients_woa.value === "") {
        ingredients_woa.classList.add("input-error");
        return;
      } else {
        ingredients_woa.classList.remove("input-error");
      }
    }

    if (ingredients.value === undefined || ingredients.value === "") {
      ingredients = null;
    } else {
      ingredients = ingredients.value;
    }
    if (ingredients_woa.value === undefined || ingredients_woa.value === "") {
      ingredients_woa = null;
    } else {
      ingredients_woa = ingredients_woa.value;
    }

    // Check positions
    var checkPosition = this.handlerCheckPosition(this.state.drinks);
    var checkPositionWOA = this.handlerCheckPosition(this.state.drinksWOA);
    if (!checkPosition || !checkPositionWOA) {
      NotificationManager.error('Hay un error con la posición.', 'Error');
      return;
    }
    
    this.setState({ page_loading: true });

    // Generate object
    var cocktail = {
      name: name.value,
      ingredients: ingredients,
      ingredientsWOA: ingredients_woa,
      drinks: this.state.drinks,
      drinksWOA: this.state.drinksWOA,
    }
    
    if (this.props.isEditMode === true) {
      // Update
      updateCocktail(this.state.id, cocktail, () => {
        this.uploadImage(this.state.id)
      });
    } else {
      // Save
      pushCocktail(cocktail, (id) => {
        this.uploadImage(id)
      });
    }
  }

  uploadImage = (id) => {
    if (this.state.fileToUpload !== undefined) {
      var storage = getStorage();
      const storageRef = ref(storage, `/cocktails/${id}.png`);
      const metadata = {
        contentType: 'image/png',
      };
      uploadBytes(storageRef, this.state.fileToUpload, metadata).then(() => {
        this.redirect();
      });
    } else {
      this.redirect();
    }
  }

  redirect = () => {
    window.location.href = "/cocktails";
  }

  /** Images **/

  handleClickFile = () => {
    document.getElementById("file").click();
  }

  onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    try {
        const reader = new FileReader();
        reader.onload = () => {
          this.setState({ 
            preview: reader.result,
            fileToUpload: files[0]
          });
        };
        reader.readAsDataURL(files[0]);
    } catch (error) {}
  }
  
  renderImage = () => {
    return (
      <div class="card card-small">
        <div class="card-header">
          <h6 class="m-0">Seleccione una imágen</h6>
        </div>
        <ul class="list-group list-group-flush">
          <li class="p-3 list-group-item" style={{ borderBottomRightRadius: "0.625rem", borderBottomLeftRadius: "0.625rem" }}>
            <div class="row">
              <div class="col">
                <form class="">
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <div class="container">
                        <div class="row">
                          <div class="col-4">
                            <img src={ this.state.preview } className="preview-image" alt=""/>
                          </div>
                          <div class="col-8">
                            <input type="file" id="file" ref="fileUploader" style={{display: "none"}} onChange={ this.onChange }/>
                            <button onClick={() => this.handleClickFile()} type="button" class="mb-2 btn btn-outline-primary mr-2">Seleccionar archivo</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
  }

  deleteCocktail = () => {
    this.setState({ page_loading: true });

    sessionStorage.removeItem("cocktails");
    const storage = getStorage();
    const desertRef = ref(storage, `/cocktails/${this.state.id}.png`);
    deleteObject(desertRef).then(() => {
      removeCocktail(this.state.id, () => {
        this.redirect() 
      });
    }).catch((error) => {
      removeCocktail(this.state.id, () => {
        this.redirect() 
      });
    });
  }

  renderRemoveBottleModal = () => {
    return(
      <div id="modal-remove-bottle" class="modal fade" role="dialog" style={{marginTop: "10%"}}>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 id="modal-title" class="modal-title">Eliminar botella</h4>
            </div>
            <div class="modal-body">
              <p id="modal-body" style={{marginBottom: '0'}}>¿Estás seguro que deseas eliminar la botella?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
              <button onClick={ () => this.deleteBottle() } type="button" class="btn btn-danger" data-dismiss="modal">Eliminar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRemoveCocktailModal = () => {
    return(
      <div id="modal-remove" class="modal fade" role="dialog" style={{marginTop: "10%"}}>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 id="modal-title" class="modal-title">Eliminar cocktail</h4>
            </div>
            <div class="modal-body">
              <p id="modal-body" style={{marginBottom: '0'}}>¿Estás seguro que deseas eliminar el cocktail?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
              <button onClick={ () => this.deleteCocktail() } type="button" class="btn btn-danger" data-dismiss="modal">Eliminar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  showDeleteCocktail = () => {
    if (this.props.isEditMode === true) {
      return <div data-toggle="modal" data-target="#modal-remove" class="floatting-button" style={{marginRight: '65px', background: '#dd4337'}}><MdDelete /></div>
    }
  }

	render() {
    return(
      <BaseLayout active_item_sidebar={3} loading={this.state.page_loading}>
        <div className="container-page">
          <HeaderTitle title="Tragos" subtitle="Bloop Machine"/>
          <div className="container-custom">
            <div class="row">
              <div class="col-md-6">
                { this.renderForm() }
              </div>

              <div class="col-md-6">
                { this.renderImage() }
              </div>

              <div class="col-md-12" style={{marginTop: '1.75em'}}>
                { this.renderTable() }
              </div>

              <div class="col-md-12" style={{marginTop: '1.75em', marginBottom: '80px'}}>
                { this.renderTableWithOutAlcohol() }
              </div>

              <button id="button-add-modal" data-toggle="modal" data-target="#modal-add-cocktail" style={{display: "none"}}/>
              <button id="button-add-modal-woa" data-toggle="modal" data-target="#modal-add-cocktail-woa" style={{display: "none"}}/>
              { this.renderAddCocktailModal() }
              { this.renderAddCocktailModalWOA() }
              { this.renderEditCocktailModal() }
              { this.renderRemoveCocktailModal() }
              { this.renderRemoveBottleModal() }
              { this.renderEditCocktailModalWOA() }

              <div onClick={() => this.onSaveData() } class="floatting-button"><MdSave /></div>
              { this.showDeleteCocktail() }
            </div>
          </div>
        </div>
      </BaseLayout>
    );  
  }

}


export default CocktailPage;