import React, { Component } from 'react';
import { MdAdd, MdDelete } from "react-icons/md";

class Table extends Component {
  constructor(){
		super();
    this.state = {
      page: 1,
      itemsToShow: 15
    }
	}

  getWidthHeader = (i) => {
    if (this.props.width_sizes !== undefined && i < this.props.width_sizes.length) {
      return this.props.width_sizes[i];
    } else {
      return "";
    }
  }

  getAlignByCol = (index) => {
    if (this.props.align !== undefined && index < this.props.align.length) {
      return this.props.align[index];
    } else {
      return "left"
    }
  }
  
  sliceData = (data, page, rowsPerPage) => {
    return data.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  };

  backPage = () => {
    var currPage = this.state.page;
    if (currPage > 1){
      currPage--;
      this.setState({ page: currPage });
    }
  }

  nextPage = () => {
    var range = (this.props.items.length/this.state.itemsToShow);
    var currPage = this.state.page;
    if ((this.props.items.length-1) % this.state.itemsToShow === 0) {
      range--;
    }
    if (this.state.page < range){
      currPage++;
      this.setState({ page: currPage });
    }
  }

  renderHeaderOfTable = () => {
    var rows = [];
    if (this.props.items.length > 0) {
      for(var i=0; i<this.props.count_rows; i++) {
        rows.push(<th id={i} scope="col" className="border-0" style={{width: this.getWidthHeader(i), textAlign: this.getAlignByCol(i)}}> { this.props.items[0][i] } </th>);
      }
    }
    return(<thead className="card-header-title"><tr>{ rows }</tr></thead>);
  }

  renderItemsOfTable = (page) => {
    var listItems = this.props.items.slice(1);
    listItems = listItems.sort((a, b) => (a[this.props.orderBy] - b[this.props.orderBy]));
    var rows = [];

    if (this.props.items.length > 1) {

      listItems = this.sliceData(listItems, page, this.state.itemsToShow);

      for(var i=0; i<listItems.length; i++) {
        rows.push(this.renderRowOfTable(listItems[i]));
      }
    }
    else if (this.props.items.length === 1) {
      rows.push(<th colSpan={this.props.count_rows} style={{textAlign: "center", display: `${ this.props.loading?'none':'table-cell' }` }}> No hay ningún elemento </th>);
    }

    return(<tbody>{ rows }</tbody>);
  }

  renderRowOfTable = (item) => {
    var row = [];
    for(var i=0; i<this.props.count_rows; i++) {
      row.push(<td  className="border-0" style={{textAlign: this.getAlignByCol(i)}}> { item[i] } </td>);
    }
    return (<tr>{ row }</tr>);
  }

  renderButtons = () => {
    return(
      <tr style={{display: this.props.items.length > this.state.itemsToShow + 1 ? 'contents':'none'}}>
        <td colspan={this.props.count_rows}>
          <div style={{textAlign: "center"}}>
            <button onClick={ () => this.backPage() } style={{marginRight: "10px", marginLeft: "10px"}} id="atras" type="button" className="btn btn-default"> Atrás </button>
            <button onClick={ () => this.nextPage() } type="button" className="btn btn-default"> Siguiente </button>
          </div>
        </td>
      </tr>
    );    
  }

  updateQuantity = () => {
    var e = document.getElementById(`select-quantity-${this.props.id}`);
    var value = e.options[e.selectedIndex].value;
    this.setState({
      itemsToShow: parseInt(value)
    })
  }

  renderHeader = () => {
    if (this.props.header_second_button !== undefined && this.props.header_second_button) {
      return(
        <div className="border-bottom card-header">
          <span>{ this.props.title }</span>

          <span style={{float: "right", width: "auto", marginLeft: "12px"}}>
            <span>Show </span>
            <select id={`select-quantity-${this.props.id}`} onChange={() => this.updateQuantity()} defaultValue="15" class="form-control" style={{float: "right", width: "auto", height: "28px", paddingTop: "1px", marginLeft: "6px"}}>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </span>

          <span className="table-button-add" onClick={() => this.props.header_callback()}><MdAdd/></span>
          <span className="table-button-add" style={{ marginRight: "10px" }} onClick={() => this.props.header_second_callback()}><MdDelete/></span>
        </div>
      );
    }
    else if (this.props.header_button !== undefined && this.props.header_button) {
      return(
        <div className="border-bottom card-header">
          <span>{ this.props.title }</span>

          <span style={{float: "right", width: "auto", marginLeft: "12px"}}>
            <span>Show </span>
            <select id={`select-quantity-${this.props.id}`} onChange={() => this.updateQuantity()} defaultValue="15" class="form-control" style={{float: "right", width: "auto", height: "28px", paddingTop: "1px", marginLeft: "6px"}}>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </span>

          <span className="table-button-add" onClick={() => this.props.header_callback()}><MdAdd/></span>
        </div>  
      );
    } else {
      return(
        <div className="border-bottom card-header">
          <span>{ this.props.title }</span>
          
          <span style={{float: "right", width: "auto", marginLeft: "12px"}}>
            <span>Show </span>
            <select id={`select-quantity-${this.props.id}`} onChange={() => this.updateQuantity()} defaultValue="15" class="form-control" style={{float: "right", width: "auto", height: "28px", paddingTop: "1px", marginLeft: "6px"}}>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </span>
        </div>
      );
    }
  }

	render() {
    return(
      <div className="row">
        <div className="col">
          <div className="mb-4 card card-small">
            { this.renderHeader() }
            <div className="p-0 pb-3 card-body">
              <table className="table mb-0">
                { this.renderHeaderOfTable() }
                { this.renderItemsOfTable(this.state.page) }
                { this.renderButtons() }
                <th colSpan={this.props.count_rows} style={{ textAlign: "center", display: `${ this.props.loading?'table-cell':'none' }` }}> <div class="loader" style={{margin: "0 auto"}}></div> </th>
              </table>
            </div>
          </div>
        </div>
      </div>
    );  
  }

}

export default Table;