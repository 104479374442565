import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

import { NotificationContainer } from 'react-notifications';
import Sidebar from '../components/Sidebar'
import 'react-notifications/lib/notifications.css';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import { MdMenu } from "react-icons/md";

class BaseLayout extends Component {

  constructor(){
    super();
    this.state = {
      redirect: undefined,
      opened: false
    }
  }
  
  componentDidMount() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        this.setState({ redirect: '/' });
      }
    });
  }

  onClickMenuSidebar = () => {
    var sidebar = document.getElementById("main-sidebar");
    if (this.state.opened) {
      sidebar.style.webkitTransform = "translate3d(-100%, 0px, 0px)"
      sidebar.style.transform = "translate3d(-100%, 0px, 0px)"
      sidebar.style.visibility = "visible"
      sidebar.style.transition = "all 0.5s ease 0s"
      sidebar.style.webkitTransition = "all 0.5s ease 0s"
    } else {
      sidebar.style.webkitTransform = "translate3d(0%, 0px, 0px)"
      sidebar.style.transform = "translate3d(0%, 0px, 0px)"
      sidebar.style.visibility = "visible"
    }
    this.setState({ opened: !this.state.opened })
  }

	render() {
    if (this.state.redirect !== undefined) {
      return <Redirect to={{ 
        pathname: this.state.redirect
      }} />
    } else {
      return(
        <div>
          <NotificationContainer/>

          <div style={{display: this.props.loading?'block':'none'}} className="loading"> 
            <div className="loader"/>
          </div>
           
          <div className="container-fluid">
            <div className="row">
              <div id="main-sidebar" className="main-sidebar col-lg-2">
                <Sidebar active_item={this.props.active_item_sidebar}/>
              </div>
  
              <div className="header-top">
                <div className="header-top-icon" onClick={ () => this.onClickMenuSidebar() } >
                  <MdMenu style={{ height: '30px', width: '30px', color: '#919191', float: 'right', margin: '10px', right: 0 }}/>
                </div>
              </div>
              
              <div className="page-container col-lg-10 offset-lg-2">
                { this.props.children }
              </div>
  
              <footer className="footer">2022 &copy; Bloop Machine</footer>
            </div>
          </div>
        </div>
      );
    }
  }

}

export default BaseLayout;