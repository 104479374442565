import { getMachines, updateCocktails, getAllCocktailsByMachineMap, getCocktailsByMachineMap } from './DBMachineManagement'
import { getDatabase, ref, child, get, set, remove } from "firebase/database";
import { NotificationManager } from 'react-notifications';

const COCKTAIL_TABLE = 'cocktails';

export function getCocktails(callback) {  
  const dbRef = ref(getDatabase());
  get(child(dbRef, COCKTAIL_TABLE)).then((data) => {
    if (data.exists()) {
      let result = {};
      for (var key in data.val()) {
        result[key] = data.val()[key]
      }      
      callback(result);
    } else {
      callback([]);
    }
  }).catch((error) => {
    NotificationManager.error('Se provocó un error al obtener los cocktails.', 'Error');
    console.error(error);
  });
}


export function getNameCocktail(id, callback){
  const dbRef = ref(getDatabase());
  get(child(dbRef, COCKTAIL_TABLE)).then((data) => {
    if (data.exists()) {
      let result = {};
      for (var key in data.val()) {
        result[key] = data.val()[key]
      }
      callback(result[id].name);
    } else {
      return(null);
    }
  }).catch((error) => {      
    NotificationManager.error('Se provocó un error al obtener los cocktails.', 'Error');
    console.error(error);
  });
}

export function pushCocktail(cocktail, callback) {
  const id = Date.now();
  uploadCocktail(id, cocktail, callback);
  sessionStorage.removeItem('images-url');
}

export function updateCocktail(id, cocktail, callback) {
  uploadCocktail(id, cocktail, callback);
  sessionStorage.removeItem('images-url');
}

export function removeCocktail(id, callback) {
  const db = getDatabase();
  remove(ref(db, `${COCKTAIL_TABLE}/${id}`))
  .then(function (){
    updateCocktailsByMachine(id, () => {
      sessionStorage.removeItem('images-url');
      callback();
    });
  }).catch((error) => {
    NotificationManager.error('Se provocó un error al eliminar el cocktail.', 'Error');
    console.error(error);
  });
}

export function removeCocktailsByBottle(bottleId, callback) {
  getCocktails((cocktails) => {
    var cocktailsToRemove = [];
    for (const [key, cocktail] of Object.entries(cocktails)) {
      cocktail.drinks.forEach(drink => {
        if (drink.bottles.includes(bottleId)) {
          cocktailsToRemove.push(cocktail.id);
        }
      });
    }
    if (cocktailsToRemove.length == 0) {
      callback();
    } else {
      var count = 0;
      cocktailsToRemove.forEach(cocktailId => {
        count++;
        removeCocktail(cocktailId, () => {
          count--;
          if (count == 0) {
            callback();
          }
        });
      });
    }
  });
}

function uploadCocktail(id, cocktail, callback) {
  const db = getDatabase();

  var drinks = [];
  cocktail.drinks.forEach(drink => {
    var bottles = "";
    drink.bottles.forEach(bottle => {
      bottles += bottle + "-";
    });
    bottles = bottles.substring(0, bottles.length -1);
    drinks.push({
      "id": drink.id,
      "default": drink.default,
      "bottles": bottles,
      "level1": drink.level1,
      "level2": drink.level2,
      "level3": drink.level3,
      "position": drink.position,
      "unit": drink.unit,
    });
  });

  var drinksWOA = [];
  cocktail.drinksWOA.forEach(drink => {
    var bottlesWOA = "";
    drink.bottles.forEach(bottleWOA => {
      bottlesWOA += bottleWOA + "-";
    });
    bottlesWOA = bottlesWOA.substring(0, bottlesWOA.length -1);
    drinksWOA.push({
      "id": drink.id,
      "default": drink.default,
      "bottles": bottlesWOA,
      "quantity": drink.quantity,
      "position": drink.position,
      "unit": drink.unit,
    });
  });

  set(ref(db, `${COCKTAIL_TABLE}/${id}` ), {
    id: id,
    name: cocktail.name,
    ingredients: cocktail.ingredients,
    ingredientsWOA: cocktail.ingredientsWOA,
    drinks: drinks,
    drinksWOA: drinksWOA
  }).then(function (){
    updateCocktailsByMachine(id, callback);
  }).catch((error) => {
    NotificationManager.error('Se provocó un error al actualizar el cocktail.', 'Error');
    console.error(error);
  });
}

export function updateCocktailsByMachine(cocktailId, callback) {
  getMachines((machines) => {
    var count = Object.keys(machines).length;
    if (count === 0) {
      callback(cocktailId);
    } else {
      for (const machineId of Object.keys(machines)) {
        getAllCocktailsByMachineMap(machineId, (allCocktailsMachine) => {
            updateCocktails(machineId, allCocktailsMachine, () => {
              count--;
              if (count === 0) {
                setTimeout(callback(cocktailId), 1000);                
              }
            });
        });
      }
    }
  });
}
