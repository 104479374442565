import { getDatabase, ref, child, get, set, remove } from "firebase/database";
import { getMachines, getBottlesByMachine, updateBottlesByMachine } from "../utils/DBMachineManagement"
import { NotificationManager } from 'react-notifications';
import { removeCocktailsByBottle } from "./DBCocktailManagement";

const BOTTLE_TABLE = 'drinks';

export function getBottles(callback) {
  const dbRef = ref(getDatabase());
  get(child(dbRef, BOTTLE_TABLE)).then((data) => {
    if (data.exists()) {
      let result = {};
      for (var key in data.val()) {
        result[key] = data.val()[key].name
      }
      callback(result);
    } else {
      callback([]);
    }
  }).catch((error) => {
    NotificationManager.error('Se provocó un error al obtener las botellas.', 'Error');
    console.error(error);
  });
}

export function pushBottle(bottle_name, callback) {
  const id = Date.now();
  const db = getDatabase();
  set(ref(db, `${BOTTLE_TABLE}/${id}` ), {
    id: id,
    name: bottle_name
  }).then(function (){
    callback();
  }).catch((error) => {
    NotificationManager.error('Se provocó un error al agregar la botella.', 'Error');
    console.error(error);
  });
}

export function updateBottle(id, bottle_name, callback) {
  const db = getDatabase();
  set(ref(db, `${BOTTLE_TABLE}/${id}` ), {
    id: parseInt(id),
    name: bottle_name
  }).then(function (){
    callback();
  }).catch((error) => {
    NotificationManager.error('Se provocó un error al modificar la botella.', 'Error');
    console.error(error);
  });
}

export function removeBottle(id, callback) {
  const db = getDatabase();
  remove(ref(db, `${BOTTLE_TABLE}/${id}` ))
  .then(function () {
    removeCocktailsByBottle(id, () => {
      updateMachineBottles(id, () => { 
        callback()
      });
    });
  }).catch((error) => {
    NotificationManager.error('Se provocó un error al eliminar la botella.', 'Error');
    console.error(error);
  });
}

function updateMachineBottles(bottleId, callback) {
  var count = 0;
  getMachines((machines) => {
    for (const machineId of Object.keys(machines)) {
      getBottlesByMachine(machineId, (bottles) => {
        var newBottles = [];
        var removeBottle = false;
        for (const [key, bottle] of Object.entries(bottles)) {
          if (bottle.bottle_id == bottleId) {
            removeBottle = true;
          } else {
            newBottles.push(bottle);
          }
        };        
        if (removeBottle) {
          count++;
          updateBottlesByMachine(machineId, newBottles, () => {
            count--;
            if (count === 0) {
                callback();
            }
          });
        }
      });
    }
    if (count === 0) {
      callback();
    }
  });
}