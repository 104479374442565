import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';

class LoginPage extends Component {

  constructor(){
    super();
    this.state = {
      redirect: undefined
    }
  }

  componentDidMount() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.setState({ redirect: '/dashboard' });
      }
    });
  }
  
  onClickLogin = () => {
    var email = document.getElementById("email");
    var password = document.getElementById("password");
    var hasError = false;
    if (email.value === undefined || email.value === "") {
      email.classList.add('input-error');
      hasError = true;
    } else {
      email.classList.remove('input-error');
    }
    if (password.value === undefined || password.value === "") {
      password.classList.add('input-error');
      hasError = true;
    } else {
      password.classList.remove('input-error');
    }
    if (hasError) {
      return;
    }

    const auth = getAuth();
    signInWithEmailAndPassword(auth, email.value, password.value)
    .then((userCredential) => {
      this.setState({ redirect: '/dashboard' });
    })
    .catch((_) => {
      password.classList.add('input-error');
    });
  }

	render() {
    if (this.state.redirect !== undefined) {
      return <Redirect to={{ 
        pathname: this.state.redirect
      }} />
    } else {
      return(
        <div className="main-content-container container-fluid px-4 my-auto" >
          <div className="row no-gutters">
            <div className="col-lg-3 col-md-5 auth-form mx-auto my-auto">
                <div className="card" style={{marginTop: '150px'}}>
                    <div className="card-body">
                        <img className="d-table mx-auto mb-3" src="./images/logo.png" style={{maxWidth: '9rem'}} alt="logo"/>
                        <div>
                            <div className="form-group">
                                <label for="email">Correo</label>
                                <input type="email" className="form-control" id="email" placeholder="Ingrese su correo"/>
                            </div>
                            <div className="form-group">
                                <label for="password">Contraseña</label>
                                <input type="password" className="form-control" id="password" placeholder="Ingrese su contraseña" />
                            </div>
                            <button className="btn btn-pill btn-accent d-table mx-auto" onClick={() => this.onClickLogin()}>Ingresar</button>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      );
    }
  }

}


export default LoginPage;