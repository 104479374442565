import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

import BaseLayout from '../components/BaseLayout'
import HeaderTitle from '../components/HeaderTitle'
import Card from '../components/Card'

import { MdAdd } from "react-icons/md";
import { getCocktails } from '../utils/DBCocktailManagement'
import { getImagesURL, downloadImage } from '../utils/ImagesManagement'

class CocktailsPage extends Component {

  constructor(){
    super();
    this.state = {
      redirect: undefined,
      redirectData: undefined,
      loading: true,
      cocktails: {},
      images: {}
    }
  }

  componentDidMount() {
    this.fetchCocktails()
  }

  fetchCocktails = () => {
    getCocktails(data => {
      this.downloadImages(data);
    });
  }

  downloadImages = (cocktails) => {
    if (Object.keys(cocktails).length === 0) {
      this.setState({
        loading: false
      });
    } else {
      getImagesURL((imagesURL) => {
        var countImages = imagesURL.length;
        if (countImages === 0) {
          this.setState({ loading: false, cocktails: cocktails });
        } else {
          imagesURL.forEach(url => {
            downloadImage(url, (cocktailId, blob) => {
              var images = this.state.images;
              images[cocktailId] = blob
              this.setState({ images: images });
              countImages--;
              if (countImages === 0) {
                this.setState({ loading: false, cocktails: cocktails });
              }
            });
          });
        }
      });
    }
  }

  renderEmptyStateOrLoading = () => {
    if (Object.keys(this.state.cocktails).length === 0 && this.state.loading === false) {
      return (
        <div style={{textAlign: 'center'}}>
          <img src="./images/cocktails_empty.png" style={{maxWidth: '50%'}} alt=""/>
          <p style={{marginTop: '50px', fontSize: '22px'}}>No hay tragos!</p>
        </div>
      );
    } else if (this.state.loading === true) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="loader" style={{border: '8px solid #d4d4d4', borderTop: '8px solid #3498db'}}/>
        </div>
      );
    }
  }

  getImage = (cocktailId) => {
    if (this.state.images[cocktailId] !== undefined && this.state.images[cocktailId] !== null) {
      return this.state.images[cocktailId];
    } else {
      return "./images/empty-photo.png";
    }
  }

  renderTable = () => {
    var items = [];
    for (const [key, item] of Object.entries(this.state.cocktails)) {
      items.push(
        <div className="col-sm-6 col-md-4 col-lg-3" onClick={ () => this.redirectToEditPage(item, key) }>
          <Card color={'#272725'} name={ item.name } id={ key } image={ this.getImage(key) } />
        </div>
      );
    }
    return(
      <div className="container-custom">
        <div class="row">
          { items }
        </div>
      </div>
    );
  }

  redirectToEditPage = (item, key) => {
    this.setState({ 
      redirect: '/edit-cocktail',
      redirectData: {
        item: item,
        image: this.getImage(key)
      }
    })
  }

  redirectToAddNewCocktail = () => {
    this.setState({ redirect: '/add-cocktail' })
  }

	render() {
    if (this.state.redirect !== undefined) {
      return <Redirect to={{ 
        pathname: this.state.redirect,
        state: this.state.redirectData 
      }} />
    } else {
      return(
        <BaseLayout active_item_sidebar={3}>
            <div className="container-page" style={{height: '100%', display: (Object.keys(this.state.cocktails).length === 0 && this.state.loading === false)?'grid':'block'}}>
              <HeaderTitle subtitle="Tragos" title="Bloop Machine"/>
              { this.renderEmptyStateOrLoading() }
              { this.renderTable() }
              <div onClick={() => this.redirectToAddNewCocktail()} class="floatting-button"><MdAdd /></div>  
            </div>
        </BaseLayout>
      );
    } 
  }

}


export default CocktailsPage;