import React, { Component } from 'react';

class Card extends Component {
  
	render() {
    return(
      <div className="card-post card">
        <div className="card-post__image" style={{backgroundColor: this.props.color, borderTopRightRadius: '10px',
          borderTopLeftRadius: '10px'}}>
          <img className="image-top-radius" src={ this.props.image } alt=""/>
        </div>
        <div className="card-body">
          <h5 className="card-title-item">
            <p href="#" className="text-fiord-blue">{ this.props.name }</p>
          </h5>
        </div>
      </div>
    );  
  }

}

export default Card;