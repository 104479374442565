import React, { Component } from 'react';

class Switch extends Component {

  constructor(){
    super();
    this.state = {
      status: false
    }
  }

  componentDidMount() {
    if (this.props.isEnabled) {
      document.getElementById("switch-" + this.props.id).click()
      this.setState({ status: true })
    }
  }

  componentDidUpdate(props){
    if(this.state.status !== props.isEnabled){
      this.setState({ status: props.isEnabled })
      if (this.props.isEnabled) {
        document.getElementById("switch-" + this.props.id).click()
      }
    }
  }

  onClick = () => {
    var newStatus = !this.state.status;
    this.setState({ status: newStatus });
    this.props.onChangeValue(newStatus);
  }

  render() {
    return(
      <label id={"switch-" + this.props.id} className="switch">
        <input id={"switch-" + this.props.id} type="checkbox" onClick={ () => this.onClick() }/>
        <span className="slider round"/>
      </label>
    );  
  }

}

export default Switch;