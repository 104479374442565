import { getCocktails } from "./DBCocktailManagement"
import { getDatabase, ref, child, get, set, remove } from "firebase/database";
import { NotificationManager } from 'react-notifications';

const MACHINE_TABLE = 'machines';

export function getMachines(callback) {
  const dbRef = ref(getDatabase());
  get(child(dbRef, `${MACHINE_TABLE}`)).then((data) => {
    if (data.exists()) {
      let result = {};
      for (var key in data.val()) {
        result[key] = data.val()[key]
      }
      callback(result);
    } else {
      callback({});
    }
  }).catch((error) => {
    NotificationManager.error('Se provocó un error al obtener las máquinas.', 'Error');
    console.error(error);
  });
}

export function removeMachine(machineId, callback) {
  const db = getDatabase();
  remove(ref(db, `${MACHINE_TABLE}/${machineId}` ))
  .then(function (){
    callback();
  }).catch((error) => {
    NotificationManager.error('Se provocó un error al eliminar la máquina.', 'Error');
    console.error(error);
  });
}

export function pushMachine(machine, callback) {
  const db = getDatabase();
  set(ref(db, `${MACHINE_TABLE}/${machine.id}` ), {
    id: machine.id,
    name: machine.name,
    settings: machine.settings
  }).then(function (){
    callback();
  }).catch((error) => {
    NotificationManager.error('Se provocó un error al agregar la máquina.', 'Error');
    console.error(error);
  });
}

export function getBottlesByMachine(machineId, callback) {
  const dbRef = ref(getDatabase());
  get(child(dbRef, `${MACHINE_TABLE}/${machineId}/bottles`)).then((data) => {
    if (data.exists()) {
      let result = {};
      for (var key in data.val()) {
        result[key] = data.val()[key]
      }
      callback(result);
    } else {
      callback([]);
    }
  }).catch((error) => {
    NotificationManager.error('Se provocó un error al obtener las botellas de la máquina.', 'Error');
    console.error(error);
  });
}

export function getMetricsByMachine(machineId, callback) {
  const dbRef = ref(getDatabase());
  get(child(dbRef, `${MACHINE_TABLE}/${machineId}/metrics`)).then((data) => {
    if (data.exists()) {
      let result = {};
      for (var key in data.val()) {
        result[key] = data.val()[key]
      }
      callback(result);
    } else {
      callback([]);
    }
  }).catch((error) => {
    NotificationManager.error('Se provocó un error al obtener las metricas de la máquina.', 'Error');
    console.error(error);
  });
}

export function updateBottlesByMachine(machineId, bottles, callback) {
  const db = getDatabase();
  set(ref(db, `${MACHINE_TABLE}/${machineId}/bottles`), bottles).then(function() {
    getAllCocktailsByMachineMap(machineId, (allCocktailsMachine) => {
        updateCocktails(machineId, allCocktailsMachine, () => {
          callback();
        });
    });
  });
}

export function getSettings(machineId, callback) {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `${MACHINE_TABLE}/${machineId}`)).then((data) => {
      if (data.exists()) {
        var result = data.val();
        callback(result);
      } else {
        callback([]);
      }
    }).catch((error) => {
      NotificationManager.error('Se provocó un error al obtener las configuraciones de la máquina.', 'Error');
      console.error(error);
    });
}

export function updateSettings(machineId, settings, callback) {
  const db = getDatabase();
  set(ref(db, `${MACHINE_TABLE}/${machineId}/settings`), settings).then(function (){
    callback();
  }).catch((error) => {
    NotificationManager.error('Se provocó un error al actualizar las configuraciones de la máquina.', 'Error');
    console.error(error);
  });
}

export function updateName(machineId, name, callback) {
  const db = getDatabase();
  set(ref(db, `${MACHINE_TABLE}/${machineId}/name`), name).then(function (){
    callback();
  }).catch((error) => {
    NotificationManager.error('Se provocó un error al actualizar las configuraciones de la máquina.', 'Error');
    console.error(error);
  });
}

export function getAllCocktailsByMachineMap(machineId, callback) {
  getBottlesByMachine(machineId, (bottlesMachine) => {
    getCocktails((allCocktails) => {     
      let result = {};
      for (const [key, cocktail] of Object.entries(allCocktails)) {
        let available = true;
        if (cocktail.drinks !== undefined && cocktail.drinks !== "") {
          cocktail.drinks.forEach( drink => {
            var hasDrinkAvailable = false;
            var bottles = drink.bottles.split("-");
            bottles.forEach(bottleId => {
              for (const [key, bottle] of Object.entries(bottlesMachine)) {
                if (bottle.bottle_id == bottleId) {
                  hasDrinkAvailable = true;
                }
              }
            });
            if (!hasDrinkAvailable) {
              available = false;
            }
          })
        }
        if (cocktail.drinksWOA !== undefined && cocktail.drinksWOA !== "") {
          cocktail.drinksWOA.forEach( drink => {
            var hasDrinkAvailable = false;
            var bottles = drink.bottles.split("-");
            bottles.forEach(bottleId => {
              for (const [key, bottle] of Object.entries(bottlesMachine)) {
                if (bottle.bottle_id == bottleId) {
                  hasDrinkAvailable = true;
                }
              }
            });
            if (!hasDrinkAvailable) {
              available = false;
            }
          })
        }
        
        if (available) {
          result[cocktail.id] = {
            "id": key
          }
        }
      }
      callback(result);
    });
  });
}

export function getCocktailsByMachineMap(machineId, callback) {
  const dbRef = ref(getDatabase());
  get(child(dbRef, `${MACHINE_TABLE}/${machineId}/cocktails`)).then((data) => {
      let result = {};
      for (var key in data.val()) {
        result[key] = {
          "id": data.val()[key].id
        }
      }
      callback(result);
  }).catch((error) => {
    NotificationManager.error('Se provocó un error al obtener los tragos de la máquina.', 'Error');
    console.error(error);
  });
}

export function updateCocktails(machineId, cocktails, callback) {
  const db = getDatabase();
  set(ref(db, `${MACHINE_TABLE}/${machineId}/cocktails`), cocktails).then(function() {
    callback();
  }).catch((error) => {
    NotificationManager.error('Se provocó un error al actualizar los tragos de la máquina.', 'Error');
    console.error(error);
  })
}
