import React, { Component } from 'react';

class Card extends Component {
    
	render() {
    return(
      <div className="card-post card" style={{backgroundColor: this.props.color}}>
        <div className="card-body" >
          <h5 className="card-title-item" style={{marginBottom: '1.5rem'}}>
            <p className="text-fiord-blue">{ this.props.name }</p>
          </h5>
          <div style={{textAlign:'center', fontWeight: 'bold'}}> 
            {this.props.value}
          </div>
        </div>
      </div>
    );  
  }

}

export default Card;