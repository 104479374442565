import React, { Component } from 'react';

import BaseLayout from '../components/BaseLayout'
import HeaderTitle from '../components/HeaderTitle'
import Table from '../components/Table'
import CardText from '../components/CardText'

import { getCodes, pushCode, updateCode } from '../utils/DBCodeManagement'
import { MdMode, MdDelete } from "react-icons/md";

class CodePage extends Component {

  constructor(){
    super();
    this.state = {
      codes: {},
      loading: true
    }
  }

  componentDidMount() {
    this.fetchCodes()
  }

  fetchCodes = () => {
    getCodes(
      this.props.location.state.machineId,
      data => {
        this.setState({
          codes: data,
          loading: false
        });
    });
  }

  onClickAddCodes = () => {
    document.getElementById("button-add-modal").click();
  }

  onClickEditCode = () => {
    document.getElementById("button-edit-modal").click();
  }

  onClickDeleteCodes = () => {
    document.getElementById("button-delete-modal").click();
  }

  updateStatus = () => {
    var code = document.getElementById("modal-edit-code").value;
    var codesUpdate = this.state.codes;
    codesUpdate[code].status = !codesUpdate[code].status;
    this.setState({ codes: codesUpdate });
    updateCode(this.props.location.state.machineId, codesUpdate, () => {})
  }

  deleteCodeSelect = () => {
    var code = document.getElementById("modal-remove-code-select").value;
    var codesUpdate = this.state.codes;
    delete codesUpdate[code];
    this.setState({ codes: codesUpdate });
    updateCode(this.props.location.state.machineId, codesUpdate, () => {})
  }

  handlerUpdateCodeInModal = (code) => {
    document.getElementById("modal-edit-code").value = code;
  }

  handlerRemoveDataInModal = (code) => {
    document.getElementById("modal-remove-code-select").value = code;
  }

  generateRandomNumber = (zeroQuantity) => {
    var number = Math.floor(Math.random() * 999999) + 1;
    number = String(number).padStart(zeroQuantity, '0')
    return number;
  }

  addCodes = () => {
    var quantity = document.getElementById("quantity_add").value;
    var new_codes = this.state.codes;
    
    var added = false;
    for(var i=0; i<quantity; i++) {
      added = false;
      while (!added) {
        var number = this.generateRandomNumber(6);
        if (new_codes[number] === undefined || new_codes[number] === null) {
          new_codes[number] = {
            "code": number,
            "status": true
          };
          added = true;
        }
      }
    }

    this.setState({ codes: new_codes });
    pushCode(this.props.location.state.machineId, new_codes, () => {})
  }

  deleteCodes = () => {
    this.setState({
      codes: [],
      number_codes: []
    });
    updateCode(this.props.location.state.machineId, [], () => {})
  }

  renderCountCodes = () => {
    var codes = this.state.codes;
    var totalCodes = 0;
    var codesAvilable = 0;
    var codesNotAvilable = 0;
    
    for (const [key, item] of Object.entries(this.state.codes)) {
      if (codes[key].status)
        codesAvilable ++;
      else
      codesNotAvilable ++;
    }
    totalCodes = codesAvilable + codesNotAvilable;
  
    return(
      <div className='container-custom'>
      <div class="row">
        <div className="col-sm-12 col-md-4 col-lg-4">
          <CardText name="Códigos totales" value={totalCodes}/>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4">
          <CardText name="Códigos habilitados" value={codesAvilable}/>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4">
          <CardText name="Códigos utilizados" value={codesNotAvilable}/>
        </div>
      </div>
    </div>
    );
  }

  renderAddCodesModal = () => {
    return(
      <div id="modal-add-codes" class="modal fade" role="dialog" style={{marginTop: "10%"}}>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Agregar códigos</h4>
            </div>
            <div class="modal-body">
              <div className="container-custom">
                <div class="row">
                  <div class="col-md-9">
                    <p className="center-vertical-p">¿Cuantos códigos deseas agregar?</p>
                  </div>
                  <div class="col-md-3">
                    <input id="quantity_add" type="number" class="form-control" defaultValue={1} />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
              <button onClick={ () => this.addCodes() } type="button" class="btn btn-success" data-dismiss="modal">Confirmar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderEditCodeModal = () => {
    return(
      <div id="modal-edit-code" class="modal fade" role="dialog" style={{marginTop: "10%"}}>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Borrar botella</h4>
            </div>
            <div class="modal-body">
              <p>¿Estás seguro en cambiar el estado del código?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
              <button onClick={ () => this.updateStatus() } type="button" class="btn btn-success" data-dismiss="modal">Confirmar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRemoveCodeSelectModal = () => {
    return(
      <div id="modal-remove-code-select" class="modal fade" role="dialog" style={{marginTop: "10%"}}>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Borrar botella</h4>
            </div>
            <div class="modal-body">
              <p>¿Estás seguro en eliminar el código seleccionado?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
              <button onClick={ () => this.deleteCodeSelect() } type="button" class="btn btn-success" data-dismiss="modal">Confirmar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDeleteCodesModal = () => {
    return(
      <div id="modal-delete-codes" class="modal fade" role="dialog" style={{marginTop: "10%"}}>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Eliminar códigos</h4>
            </div>
            <div class="modal-body">
              <p style={{marginBottom: '0'}}>¿Estás seguro que deseas eliminar los códigos?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
              <button onClick={ () => this.deleteCodes() } type="button" class="btn btn-success" data-dismiss="modal">Confirmar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTable = () => {
    var items = [["Código", "Estado", "Acción"]];
    for (const [key, item] of Object.entries(this.state.codes)) {
      items.push([ 
        key, 
        item.status?<span style={{color: "#35a250"}}>Activo</span>:<span style={{color: "#df736b"}}>Inactivo</span> , 
        <div>
          <button onClick={() => this.handlerUpdateCodeInModal(key)} data-toggle="modal" data-target="#modal-edit-code" class="button button-edit"><MdMode style={{color: "#fff"}}/></button>
          <button onClick={() => this.handlerRemoveDataInModal(key)} data-toggle="modal" data-target="#modal-remove-code-select" class="button button-delete"><MdDelete style={{color: "#fff"}}/></button>
        </div>
       ]);
    };


    return (
      <div className="container-custom">
        <Table title="Códigos" count_rows="3" width_sizes={ ['33%', '33%', '34%'] } items={ items } 
                loading={ this.state.loading } orderBy={0} align={['left', 'left', 'center']}
                header_button={true} header_callback={ () => this.onClickAddCodes() } 
                header_second_button={true} header_second_callback={ () => this.onClickDeleteCodes()} />
      </div>
    );
  }

	render() {
      return(
        <BaseLayout active_item_sidebar={4}>
            <div className="container-page">
              <HeaderTitle title="Dashboard" subtitle="Códigos"/>
              
              { this.renderCountCodes()}
              
              <button id="button-add-modal" data-toggle="modal" data-target="#modal-add-codes" style={{display: "none"}}/>
              <button id="button-delete-modal" data-toggle="modal" data-target="#modal-delete-codes" style={{display: "none"}}/>
              { this.renderTable() }
              { this.renderAddCodesModal() }
              { this.renderDeleteCodesModal() }
              { this.renderEditCodeModal() }
              { this.renderRemoveCodeSelectModal() }
            </div>
        </BaseLayout>
      );
  }
}

export default CodePage;