import React, { Component } from 'react';

import CocktailPage from '../pages/CocktailPage'

class EditCocktailsPage extends Component {

	render() {
    return(
      <CocktailPage 
        isEditMode={true} 
        id={this.props.location.state.item.id}
        name={this.props.location.state.item.name}
        ingredients={this.props.location.state.item.ingredients}
        ingredientsWOA={this.props.location.state.item.ingredientsWOA}
        drinks={this.props.location.state.item.drinks}
        drinksWOA={this.props.location.state.item.drinksWOA}
        image={this.props.location.state.image}
      />
    );  
  }

}

export default EditCocktailsPage;