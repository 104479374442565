import React, { Component } from 'react';

class HeaderTitle extends Component {

	render() {
    return(
      <div className="page-header py-4 no-gutters row">
        <div className="text-sm-left mb-3 text-center text-md-left mb-sm-0 col-12 col-sm-4">
          <span className="text-uppercase page-subtitle">{ this.props.title }</span>
          <h3 className="page-title">{ this.props.subtitle }</h3>
        </div>
      </div>
    );  
  }

}

export default HeaderTitle;