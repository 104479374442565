import React, { Component } from 'react';

import { Redirect } from "react-router-dom";

import { getAuth, signOut } from "firebase/auth";
import { MdMiscellaneousServices, MdLogout, MdOutlineCoffeeMaker, MdFilterAlt, MdDashboard } from "react-icons/md";

class Sidebar extends Component {

  constructor(){
    super();
    this.state = {
      redirect: undefined
    }
  }

  onClickItem = (item, path) => {
    if (item === this.props.active_item) {
      window.location.href = path;
    } else {
      this.setState({ redirect: path })
    }
  }

  onClickLogOut = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      this.setState({ redirect: "/" })
    }).catch((_) => {
      alert("Se provocó un error");
    });
  }

  getClassName = (item) => {
    if (this.props.active_item === item) {
      return "nav-item active";
    } else {
      return "nav-item";
    }
  }

	render() {
    if (this.state.redirect !== undefined) {
      return <Redirect to={this.state.redirect} />
    } else {
      return(
        <ul className="nav flex-column">
          <li className="header-slider">
            <img src="./images/logo.png" alt="Logo" width="70%"/>
          </li>

          <li className={this.getClassName(1)} onClick={() => this.onClickItem(1, "/dashboard")}><MdDashboard/> <span className="nav-item-text">Inicio</span></li>

          <li className={this.getClassName(2)} onClick={() => this.onClickItem(2, "/bottles")}><MdMiscellaneousServices/> <span className="nav-item-text">Botellas</span></li>          

          <li className={this.getClassName(3)} onClick={() => this.onClickItem(3, "/cocktails")}><MdFilterAlt/> <span className="nav-item-text">Tragos</span></li>
          
          <li className={this.getClassName(4)} onClick={() => this.onClickItem(4, "/machines")}><MdOutlineCoffeeMaker/> <span className="nav-item-text">Maquinas</span></li>

          <li className={this.getClassName(5)} onClick={() => this.onClickLogOut()}><MdLogout/> <span className="nav-item-text">Cerrar Sesión</span></li>
        </ul>
      );
    }
  }

}

export default Sidebar;
