import { getDatabase, ref, child, get, set } from "firebase/database";
import { NotificationManager } from 'react-notifications';

const CODE_TABLE = 'codes';

export function getCodes(machineId, callback) {
  const dbRef = ref(getDatabase());
  get(child(dbRef, `machines/${machineId}/${CODE_TABLE}`)).then((data) => {
    if (data.exists()) {
      var result = data.val();
      callback(result);
    } else {
      callback([]);
    }
  }).catch((error) => {
    NotificationManager.error('Se provocó un error al obtener los códigos.', 'Error');
    console.error(error);
  });
}

export function pushCode(machineId, codes, callback) {
  const db = getDatabase();
  set(ref(db, `machines/${machineId}/${CODE_TABLE}` ), codes).then(function (){
    callback();
  }).catch((error) => {
    NotificationManager.error('Se provocó un error al crear los códigos.', 'Error');
    console.error(error);
  });
}

export function updateCode(machineId, codes, callback) {
  const db = getDatabase();
  set(ref(db, `machines/${machineId}/${CODE_TABLE}` ), codes).then(function (){
    callback();
  }).catch((error) => {
    NotificationManager.error('Se provocó un error al eliminar los códigos.', 'Error');
    console.error(error);
  });
}
