import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

import BaseLayout from '../components/BaseLayout'
import HeaderTitle from '../components/HeaderTitle'
import Table from '../components/Table'
import { getMachines, removeMachine } from '../utils/DBMachineManagement'

class Machine1Page extends Component {

  constructor(){
    super();
    this.state = {
      redirect: undefined,
      redirectData: undefined,
      loading: true,
      machines: [],
      codeActivationEnabled: false,
      cocktailWithAlcohol: false
    }
  }

  componentDidMount() {
    this.fetchMachines()
  }

  fetchMachines = () => {
    getMachines(data => {
      this.setState({
        machines: data,
        loading: false
      });
    });
  }

  onClickRedirect = (url, machineId) => {
    this.setState({ 
      redirect: url,
      redirectData: {
        machineId: machineId
      }
    });
  }

  removeMachine = () => {
    var machineId = document.getElementById("modal-remove").name;
    var inputConfirm = document.getElementById("input-confirm");

    if (inputConfirm.value !== "Estoy seguro que quiero borrar la maquina") {
      inputConfirm.classList.add("input-error");
    } else {
      inputConfirm.classList.remove("input-error");
      document.getElementById("cancel-remove-modal").click();
      removeMachine(machineId, () => {
        this.fetchMachines();
      });
    }
  }

  onClickRemoveMachine = (machineId) => {
    document.getElementById("modal-remove").name = machineId;
    document.getElementById("input-confirm").value = "";
    document.getElementById("input-confirm").classList.remove("input-error");
    document.getElementById("button_remove_modal").click();
  }

  renderRemoveMachineModal = () => {
    return(
      <div id="modal-remove" class="modal fade" role="dialog" style={{marginTop: "10%"}}>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Borrar Máquina</h4>
            </div>
            <div class="modal-body">
              <p>¿Estás seguro que quieres borrar la máquina?</p>
              <p style={{fontSize: '14px', color: '#717171'}}>Ingrese: "Estoy seguro que quiero borrar la maquina"</p>
              <input id="input-confirm" style={{width: '100%'}} className="input-custom" />
            </div>
            <div class="modal-footer">
              <button id="cancel-remove-modal" type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
              <button onClick={ () => this.removeMachine() } type="button" class="btn btn-danger">Eliminar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTable = () => {
    var items = [["Nombre", "Acción"]];
    for (const [key, value] of Object.entries(this.state.machines)) {
      console.log(value);
      items.push([ 
        value.name,
        <div>
          <button type="button" class="btn" style={{marginRight: '8px'}} onClick={() => this.onClickRedirect("/codes", key)}>Códigos</button>
          <button type="button" class="btn" style={{marginRight: '8px'}} onClick={() => this.onClickRedirect("/machine-cocktails", key)}>Tragos</button>
          <button type="button" class="btn" style={{marginRight: '8px'}} onClick={() => this.onClickRedirect("/settings", key)}>Configuraciones</button>
          <button type="button" class="btn btn-danger" style={{marginRight: '8px'}} onClick={() => this.onClickRemoveMachine(key)}>Eliminar</button>
        </div>
       ]);
    }
    return (
      <div className="container-custom">
        <Table title="Máquinas" count_rows="2" width_sizes={ ['20%', '80%'] } items={ items } loading={ this.state.loading } orderBy={0} align={['left', 'right']} />
      </div>
    );
  }

	render() {
    if (this.state.redirect !== undefined) {
      return <Redirect to={{ 
        pathname: this.state.redirect,
        state: this.state.redirectData  
      }} />
    } else {
      return(
        <BaseLayout active_item_sidebar={4}>
            <div className="container-page">
              <HeaderTitle subtitle="Maquinas" title="Bloop Machine"/>

              { this.renderRemoveMachineModal() }
              <button id="button_remove_modal" data-toggle="modal" data-target="#modal-remove" style={{display: "none"}}/>

              { this.renderTable() }
            </div>
        </BaseLayout>
      );  
    }
  }

}


export default Machine1Page;