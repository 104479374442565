import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import BottlesPage from './pages/BottlesPage';
import AddCocktailsPage from './pages/AddCocktailsPage';
import EditCocktailsPage from './pages/EditCocktailsPage';
import CocktailsPage from './pages/CocktailsPage';
import MachinesPage from './pages/MachinesPage';
import CodePage from './pages/CodePage';
import SettingsPage from './pages/SettingsPage';
import MachineCocktailsPage from './pages/MachineCocktailsPage';

import { initializeApp } from "firebase/app";

import config from "./config.json";

const firebaseConfig = {
  apiKey: config.API_KEY,
  authDomain: config.AUTH_DOMAIN,
  databaseURL: config.DATABASE_URL,
  projectId: config.PROJECT_ID,
  storageBucket: config.STORAGE_BUCKET,
  messagingSenderId: config.MESSAGING_SENDER_ID,
  appId: config.APP_ID,
  measurementId: config.MEASUREMENT_ID
};
initializeApp(firebaseConfig);

const Router = () => {
  return (
    <BrowserRouter basename="/">
      <Switch>
        <Route exact path={"/"} component={LoginPage} />
        <Route exact path={"/dashboard"} component={DashboardPage} />
        <Route exact path={"/bottles"} component={BottlesPage} />
        <Route exact path={"/cocktails"} component={CocktailsPage} />
        <Route exact path={"/add-cocktail"} component={AddCocktailsPage} />
        <Route exact path={"/edit-cocktail"} component={EditCocktailsPage} />
        <Route exact path={"/machines"} component={MachinesPage} />
        <Route exact path={"/codes"} component={CodePage} />
        <Route exact path={"/settings"} component={SettingsPage} />
        <Route exact path={"/machine-cocktails"} component={MachineCocktailsPage} />
      </Switch>
    </BrowserRouter>
  );
}

export default Router;