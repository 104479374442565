import React, { Component } from 'react';

import BaseLayout from '../components/BaseLayout'
import HeaderTitle from '../components/HeaderTitle'
import Table from '../components/Table'

import { NotificationManager } from 'react-notifications';

import { getBottles, updateBottle, removeBottle, pushBottle } from '../utils/DBBottleManagement'
import { MdAdd, MdMode, MdDelete } from "react-icons/md";

class BottlesPage extends Component {

  constructor(){
    super();
    this.state = {
      bottles: [],
      loading: true
    }
  }

  componentDidMount() {
    this.fetchBottles()
  }

  fetchBottles = () => {
    getBottles(data => {
      this.setState({
        bottles: data,
        loading: false
      });
    });
  }

  addBottle = () => {
    var name = document.getElementById("modal-add-bottle");
    if (name.value === undefined || name.value === "") {
      name.classList.add("input-error");
    } else {
      name.classList.remove("input-error");
      document.getElementById("modal_cancel_add").click();
      pushBottle(name.value, this.fetchBottles);
      NotificationManager.success('Se agregó la botella correctamente.', 'Perfecto!');
    }
  }

  updateBottle = () => {
    var input = document.getElementById("modal-edit-bottle");
    if (input.value === undefined || input.value === "") {
      input.classList.add("input-error");
    } else {
      input.classList.remove("input-error");
      var id = input.name;
      var name = input.value;
      input.value = "";
      updateBottle(id, name, this.fetchBottles);
      document.getElementById("modal_cancel_edit").click();
      NotificationManager.success('Se modificó la botella correctamente.', 'Perfecto!');
    }
  }

  removeBottle = () => {
    var id = document.getElementById("modal-edit-bottle").name;
    removeBottle(id, this.fetchBottles);
    NotificationManager.success('Se eliminó la botella correctamente.', 'Perfecto!');
  }

  handlerUpdateDataInModal = (id, name) => {
    document.getElementById("modal-edit-bottle").value = name;
    document.getElementById("modal-edit-bottle").name = id;
    document.getElementById("modal-edit-bottle").classList.remove("input-error");
  }

  handlerRemoveDataInModal = (id) => {
    document.getElementById("modal-edit-bottle").name = id;
  }

  renderTable = () => {
    var items;
    let sizes;
    const anchoPantalla = window.innerWidth;
    if (anchoPantalla < 580) {
      items = [["Nombre", "Acción"]];
      sizes =  ['70%', '30%']
    } else {
      items = [["Id", "Nombre", "Acción"]];
      sizes =  ['25%', '50%', '25%']
    }
    
    for (const [key, item] of Object.entries(this.state.bottles)) {
      if (anchoPantalla < 580) {
        items.push([ 
          item, 
          <div>
            <button onClick={() => this.handlerUpdateDataInModal(key, item)} data-toggle="modal" data-target="#modal-edit" class="button button-edit"><MdMode style={{color: "#fff"}}/></button>
            <button onClick={() => this.handlerRemoveDataInModal(key)} data-toggle="modal" data-target="#modal-remove" class="button button-delete"><MdDelete style={{color: "#fff"}}/></button>
          </div>
        ]);
      } else {
        items.push([ 
          key, 
          item, 
          <div>
            <button onClick={() => this.handlerUpdateDataInModal(key, item)} data-toggle="modal" data-target="#modal-edit" class="button button-edit"><MdMode style={{color: "#fff"}}/></button>
            <button onClick={() => this.handlerRemoveDataInModal(key)} data-toggle="modal" data-target="#modal-remove" class="button button-delete"><MdDelete style={{color: "#fff"}}/></button>
          </div>
        ]);
      }
    }

    return (
      <div className="container-custom">
        <Table title="Bebidas" count_rows="3" width_sizes={ sizes } items={ items } loading={ this.state.loading } orderBy={1} align={['left', 'left', 'right']}/>
      </div>
    );
  }

  renderEditModal = () => {
    return(
      <div id="modal-edit" class="modal fade" role="dialog" style={{marginTop: "10%"}}>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Editar botella</h4>
            </div>
            <div class="modal-body">
              <p>Ingrese el nombre de la botella</p>
              <input id="modal-edit-bottle" className="input-custom" style={{width: "100%"}} placeholder="Nombre"/>
            </div>
            <div class="modal-footer">
              <button id="modal_cancel_edit" type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
              <button onClick={ () => this.updateBottle() } type="button" class="btn btn-success">Modificar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRemoveModal = () => {
    return(
      <div id="modal-remove" class="modal fade" role="dialog" style={{marginTop: "10%"}}>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Borrar botella</h4>
            </div>
            <div class="modal-body">
              <p>¿Estás seguro que quieres borrar la botella?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
              <button onClick={ () => this.removeBottle() } type="button" class="btn btn-danger" data-dismiss="modal">Eliminar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  openAddModal = () => {
    document.getElementById("modal-add-bottle").classList.remove("input-error");
    document.getElementById("modal-add-bottle").value = "";
    document.getElementById("button_add_modal").click();
  }

  renderAddModal = () => {
    return(
      <div id="modal-add" class="modal fade" role="dialog" style={{marginTop: "10%"}}>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Agregar botella</h4>
            </div>
            <div class="modal-body">
              <p>Ingrese el nombre de la botella</p>
              <input id="modal-add-bottle" className="input-custom" style={{width: "100%"}} placeholder="Nombre"/>
            </div>
            <div class="modal-footer">
              <button id="modal_cancel_add" type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
              <button onClick={ () => this.addBottle() } class="btn btn-success" type="button">Agregar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

	render() {
    return(
      <BaseLayout active_item_sidebar={2}>
          <div className="container-page">
          <HeaderTitle subtitle="Botellas" title="Bloop Machine"/>
            { this.renderTable() }
            { this.renderEditModal() }
            <button id="button_edit_modal" data-toggle="modal" data-target="#modal-edit" style={{display: "none"}}/>
            { this.renderRemoveModal() }
            { this.renderAddModal() }
            <button id="button_add_modal" data-toggle="modal" data-target="#modal-add" style={{display: "none"}}/>
            <div onClick={ () => this.openAddModal() } class="floatting-button"><MdAdd /></div>
          </div>
      </BaseLayout>
    );  
  }

}

export default BottlesPage;