import React, { Component } from 'react';

import BaseLayout from '../components/BaseLayout'
import HeaderTitle from '../components/HeaderTitle'
import Table from '../components/Table'
import Switch from '../components/Switch'


import { getBottlesByMachine, getSettings, updateName, updateSettings, updateBottlesByMachine } from '../utils/DBMachineManagement'
import { getBottles } from '../utils/DBBottleManagement'
import { MdDelete } from "react-icons/md";

import { NotificationManager } from 'react-notifications';

import { getDatabase, ref, onValue } from "firebase/database";

class SettingsPage extends Component {

  constructor(){
    super();
    this.state = {
      setting: undefined,
      bottles: [],
      machineBottles: [],
      loading: true,
      bottle_to_remove: '',
      name: '',
      saveMetrics: false,
      alcoholEnabled: false,
      metricsEnabled: false,
      positions: []
    }
  }

  componentDidMount() {
    this.loadData();

    // Listener
    var machineId = this.props.location.state.machineId;
    const db = getDatabase();
    const starCountRef = ref(db, 'machines/' + machineId + '/bottles');
    onValue(starCountRef, (_) => {
      this.loadData()
    });
  }

  loadData = () => {
    var machineId = this.props.location.state.machineId;
    getSettings(machineId, (data) => {
      this.setState({
        setting: data.settings,
        name: data.name,
        codeActivationEnabled: data.settings.activationCodesEnabled,
        alcoholEnabled: data.settings.alcohol,
        saveMetrics: data.settings.saveMetrics
      })
      getBottles((item) => {
        this.setState({bottles: item})
        getBottlesByMachine(machineId, (data) => {
          this.setState({
            machineBottles: data,
            loading: false
          });
        });
      });
    });
  }

  renderGeneralSettingsCard = () => {
    var items = [["Configuración", "Estado"]];
    if (!this.state.loading) {
      items = items.concat([
        ["Máquina", this.state.name],
        ["Capacidad del vaso", this.state.setting.capacityGlass],
        ["Peso del vaso", this.state.setting.weightGlass],
        ["Cantidad de botellas", this.state.setting.maxBottles],
        ["Calibración balanza", this.state.setting.calibrationScale],
        ["Código de activación", this.state.setting.activationCodesEnabled?'Habilitado':'Deshabilitado' ],
        ["Tragos con alcohol", this.state.setting.alcohol?'Habilitado':'Deshabilitado' ],
        ["Metricas", this.state.setting.saveMetrics?'Habilitado':'Deshabilitado' ]
      ]);
    }
    return (
      <div className="container-custom">
        <Table id="2" title="Generales" count_rows="2" width_sizes={ ['70%', '30%'] } items={ items } loading={ this.state.loading } orderBy={0} 
                header_button={true} header_callback={() => { this.openSettingsModal() } } align={['left', 'center']}/>
      </div>
    );
  }

  openSettingsModal = () => {
    this.setState({ codeActivationEnabled: this.state.setting.activationCodesEnabled }, () => {
          this.state.alcoholEnabled = this.state.setting.alcohol;
          this.resetModalData();
          document.getElementById("button-settings").click();
    });
  }

  resetModalData = () => {
    document.getElementById("name_modal").value = this.state.name;
    document.getElementById("capacity_modal").value = this.state.setting.capacityGlass;
    document.getElementById("weight_modal").value = this.state.setting.weightGlass;
    document.getElementById("quantity_modal").value = this.state.setting.maxBottles;
    document.getElementById("calibration_modal").value = this.state.setting.calibrationScale;
    document.getElementById("capacity_modal").classList.remove("input-error");
    document.getElementById("weight_modal").classList.remove("input-error");
    document.getElementById("quantity_modal").classList.remove("input-error");
    document.getElementById("calibration_modal").classList.remove("input-error");
  }

  renderGeneralSettingsModal = () => {
    return(
      <div id="modal-settings" className="modal fade" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Modificar configuraciones</h4>
            </div>
            <div className="modal-body" style={{padding: 0}}>              
              <table className="table mb-0">
                <thead className="card-header-title">
                  <tr>
                    <th id="0" className="border-0" style={{width: "70%"}}> Configuración </th>
                    <th id="1" className="border-0" style={{width: "30%"}}> Estado </th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="border-0" style={{verticalAlign: "middle"}}> Maquina </td>
                    <td class="border-0"><input id="name_modal" type="text" className="input-custom" defaultValue="Machine"/></td>
                  </tr>
                  <tr>
                    <td className="border-0" style={{verticalAlign: "middle"}}> Capacidad del vaso </td>
                    <td class="border-0"><input id="capacity_modal" type="number" className="input-custom" defaultValue="300"/></td>
                  </tr>
                  <tr>
                    <td className="border-0" style={{verticalAlign: "middle"}}> Peso del vaso </td>
                    <td className="border-0"><input id="weight_modal" type="number" className="input-custom" defaultValue="250"/></td>
                  </tr>
                  <tr>
                    <td className="border-0" style={{verticalAlign: "middle"}}> Cantidad de botellas </td>
                    <td className="border-0"><input id="quantity_modal" type="number" className="input-custom" defaultValue="6"/></td>
                  </tr>
                  <tr>
                    <td className="border-0" style={{verticalAlign: "middle"}}> Calibración balanza </td>
                    <td className="border-0"><input id="calibration_modal" type="number" className="input-custom" defaultValue="100.0"/></td>
                  </tr>
                  <tr>
                    <td className="border-0" style={{verticalAlign: "middle"}}> Métricas </td>
                    <td className="border-0" style={{textAlign: "end"}}> 
                      <Switch
                        id={"3"}
                        isEnabled={ this.state.saveMetrics }
                        onChangeValue={ (value3) => { 
                          this.setState({ saveMetrics: value3 }) } }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="border-0" style={{verticalAlign: "middle"}}> Código de activación </td>
                    <td className="border-0" style={{textAlign: "end"}}> 
                      <Switch
                        id={"2"}
                        isEnabled={ this.state.codeActivationEnabled }
                        onChangeValue={ (value) => { 
                          this.setState({ codeActivationEnabled: value }) } }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="border-0" style={{verticalAlign: "middle"}}> Tragos con alcohol </td>
                    <td className="border-0" style={{textAlign: "end"}}> 
                      <Switch
                        id={"1"}
                        isEnabled={ this.state.alcoholEnabled }
                        onChangeValue={ (value2) => { 
                          this.setState({ alcoholEnabled: value2 }) } }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button id="close_modal_settings" type="button" className="btn btn-default" data-dismiss="modal">Cancelar</button>
              <button onClick={ () => this.onSaveSettings() } type="button" className="btn btn-success">Modificar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onSaveSettings = () => {
    var name = document.getElementById("name_modal");
    var quantity = document.getElementById("quantity_modal");
    var capacity = document.getElementById("capacity_modal");
    var weight  = document.getElementById("weight_modal");
    var calibration  = document.getElementById("calibration_modal");
    // Validate has data
    var hasError = false;
    if (name.value === undefined || name.value === "") {
      name.classList.add("input-error");
      hasError = true;
    } else {
      name.classList.remove("input-error");
    }
    if (quantity.value === undefined || quantity.value === "") {
      quantity.classList.add("input-error");
      hasError = true;
    } else {
      quantity.classList.remove("input-error");
    }
    if (capacity.value === undefined || capacity.value === "") {
      capacity.classList.add("input-error");
      hasError = true;
    } else {
      capacity.classList.remove("input-error");
    }
    if (weight.value === undefined || weight.value === "") {
      weight.classList.add("input-error");
      hasError = true;
    } else {
      weight.classList.remove("input-error");
    }
    if (hasError) {
      return;
    }
    var settings = {
      activationCodesEnabled: this.state.codeActivationEnabled,
      alcohol: this.state.alcoholEnabled,
      saveMetrics: this.state.saveMetrics,
      capacityGlass: parseInt(capacity.value),
      maxBottles: parseInt(quantity.value),
      weightGlass: parseInt(weight.value),
      calibrationScale: parseFloat(calibration.value)
    }
    updateSettings(this.props.location.state.machineId, settings, () => {});
    updateName(this.props.location.state.machineId, name.value, () => {});
    this.setState({ 
      name: name.value,
      setting: settings
     })
    document.getElementById("close_modal_settings").click();
  }

  onClickAddBottle = () => {
    if (this.state.machineBottles.length === this.state.setting.maxBottles) {
      NotificationManager.warning('Se encuentran todas las botellas asignadas.', 'Alerta!');
    } else {
      document.getElementById("button-edit-bottle").click();
      // Render available positions
      var positions = [];
      for (const [key, item] of Object.entries(this.state.machineBottles)) {
        positions.push(item.position);
      }
      this.setState({ positions: positions })
    }
  }

  onClickDeleteBottles = () => {
    document.getElementById("button-delete-modal").click();
  }

  handlerRemoveRow = (position) => {
    document.getElementById("modal-remove").name = position;
  }
  
  addBottle = () => {
    var select_position = document.getElementById("position-select");
    var position = select_position.options[select_position.selectedIndex].value;
    var quantity = document.getElementById("quantity").value;
    select_position = document.getElementById("bottles-select");
    var bottle_id = select_position.options[select_position.selectedIndex].value;
    // Validate data
    if (quantity === undefined || quantity === "") {
      document.getElementById("quantity").classList.add("input-error");
      return;
    } else {
      document.getElementById("quantity").classList.remove("input-error");
      document.getElementById("button_close_modal").click();
    }

    var machineBottles = this.state.machineBottles;    
    machineBottles[`${position}-${bottle_id}`] = {
      "position": parseInt(position),
      "bottle_id": parseInt(bottle_id),
      "quantity": parseInt(quantity)
    }
    this.setState({ machineBottles: machineBottles });
    updateBottlesByMachine(this.props.location.state.machineId, machineBottles, () => {});
  }

  deleteBottles(){
    this.setState({
      machineBottles: [],
      positions: []
    });
    updateBottlesByMachine(this.props.location.state.machineId, {}, () => {});
  }

  removeBottleMachine = () => {
    var position = document.getElementById("modal-remove").name;
    var newBottles = {};
    for (const [key, item] of Object.entries(this.state.machineBottles)) {
      if (item.position !== position) {
        newBottles[key] = item;
      }
    };
    this.setState({ machineBottles: newBottles });
    updateBottlesByMachine(this.props.location.state.machineId, newBottles, () => {});
  }

  renderRemoveCocktailModal = () => {
    return(
      <div id="modal-remove" class="modal fade" role="dialog" style={{marginTop: "10%"}}>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Eliminar Botella</h4>
            </div>
            <div class="modal-body">
              <p style={{marginBottom: '0'}}>¿Estás seguro que deseas eliminar la botella?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
              <button onClick={ () => this.removeBottleMachine() } type="button" class="btn btn-success" data-dismiss="modal">Confirmar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderBottlesCard = () => {
    var items = [["Posición", "Nombre", "Capacidad", "Acción"]];
    for (const [key, item] of Object.entries(this.state.machineBottles)) {
      items.push([ 
        item.position, 
        this.state.bottles[item.bottle_id], 
        item.quantity,
        <button onClick={() => this.handlerRemoveRow(item.position)} data-toggle="modal" data-target="#modal-remove" class="button button-delete"><MdDelete style={{color: "#fff"}}/></button>
       ]);
    }
    if (!this.state.loading && this.state.setting !== undefined) {
      for(let i=0; i<this.state.setting.maxBottles; i++) {
        let hasPos = false;
        for (const [key, item] of Object.entries(this.state.machineBottles)) {
          if (i+1 === item.position) {
            hasPos = true;
          }
        };
        if (!hasPos) {
          items.push([i+1, "-", "-", ""]);
        }
      }
    }
    return (
      <div className="container-custom">
        <Table id="1" title="Bebidas" count_rows="4" width_sizes={ ['15%', '40%', '25%', '20%'] } items={ items } loading={ this.state.loading } orderBy={0} 
                header_button={true} header_callback={() => this.onClickAddBottle() } align={['center', 'left', 'center', 'center']}
                header_second_button={true} header_second_callback={ () => this.onClickDeleteBottles()} />
      </div>
    );
  }

  renderSelectBottle = () => {
      var items = [];
      for (const [key, value] of Object.entries(this.state.bottles)) {
        items.push(<option value={ key }>{ value }</option>)
      }
      return(
        <select id="bottles-select" className="input-custom" style={{ width: "100%", marginBottom: '1rem' }}>
          { items }
        </select>
      );
  }

  renderSelectPosition = () => {
    if (this.state.setting !== undefined) {
      var items = [];
      for (var i=0; i<this.state.setting.maxBottles; i++) {
        if (this.state.positions.includes(i+1) == false) {
          items.push(<option value={ i+1 }>{ i+1 }</option>);
        }
      }
      return(
        <select id="position-select" className="input-custom" style={{ width: "100%", marginBottom: '1rem' }}>
          { items }
        </select>
      );
    }
  }

  renderEditBottleModal = () => {
    return(
      <div id="modal-edit-bottle" class="modal fade" role="dialog" style={{marginTop: "10%"}}>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Agregar botella</h4>
            </div>
            <div class="modal-body">
              <div class="row" style={{ margin: '0', marginTop:'1rem' }}>
                <div class="column" style={{width: '50%', paddingRight: '7px'}}>
                  <p style={{marginBottom: '0'}}>Botella</p> 
                  { this.renderSelectBottle() }
                </div>
                <div class="column" style={{width: '25%', paddingRight: '7px', paddingLeft: '7px'}}>
                  <p style={{marginBottom: '0'}}>Posición</p>
                  { this.renderSelectPosition() }
                </div>
                <div class="column" style={{width: '25%', paddingLeft: '7px'}}>
                  <p style={{marginBottom: '0'}}>Capacidad</p>
                  <input id="quantity" type="number" class="form-control" style={{marginBottom: '1rem'}}/>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button id="button_close_modal" type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
              <button onClick={ () => this.addBottle() } type="button" class="btn btn-success">Agregar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDeleteBottlesModal = () => {
    return(
      <div id="modal-delete-bottles" class="modal fade" role="dialog" style={{marginTop: "10%"}}>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Eliminar códigos</h4>
            </div>
            <div class="modal-body">
              <p style={{marginBottom: '0'}}>¿Estás seguro que deseas eliminar todas las botellas?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
              <button onClick={ () => this.deleteBottles() } type="button" class="btn btn-success" data-dismiss="modal">Confirmar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

	render() {
    return(
      <BaseLayout active_item_sidebar={4}>
          <div className="container-page">
            <HeaderTitle title="Dashboard" subtitle="Settings"/>
            <div className="container-custom">
              <div class="row">
                <div className="col-sm-12 col-md-6" style={{padding: 0}}>
                  { this.renderGeneralSettingsCard() }
                  { this.renderGeneralSettingsModal() }
                  <button id="button-settings" data-toggle="modal" data-target="#modal-settings" style={{display: "none"}}/>
                </div>
                <div className="col-sm-12 col-md-6" style={{padding: 0}}>
                  { this.renderBottlesCard() }
                  { this.renderEditBottleModal() }
                  { this.renderRemoveCocktailModal() }
                  { this.renderDeleteBottlesModal() }
                  <button id="button-edit-bottle" data-toggle="modal" data-target="#modal-edit-bottle" style={{display: "none"}}/>
                  <button id="button-delete-modal" data-toggle="modal" data-target="#modal-delete-bottles" style={{display: "none"}}/>
                </div>
              </div>
            </div>
          </div>
      </BaseLayout>
    );
  }

}


export default SettingsPage;