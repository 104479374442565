import { getStorage, ref, listAll, getBlob } from "firebase/storage";
import { NotificationManager } from 'react-notifications';

const default_image = "./images/empty-photo.png";

var imagesBlob = {};

export function getImagesURL(callback) {
  var images = sessionStorage.getItem('images-url');
  if (images !== null) {
    var result = JSON.parse(images);
    callback(result);
  } else {
    var imagesList = [];
    var storage = getStorage();
    const listRef = ref(storage, 'cocktails');
    listAll(listRef)
    .then((res) => {
      res.items.forEach((itemRef) => {
        imagesList.push(itemRef.fullPath);
      });
      var dataJSON = JSON.stringify(imagesList);
      sessionStorage.setItem('images-url', dataJSON);
      callback(imagesList);
    }).catch((error) => {
      NotificationManager.error('Se provocó un error al obtener las imágenes.', 'Error');
      console.log("error", error);
    });
  }
}

export function downloadImage(path, callback) {
  //TODO: CREAR SINGLETON O ALGO DE ESO
  var id = path.split("/")[1].split(".")[0];
  if (imagesBlob[id] !== undefined && imagesBlob[id] !== null) {
    callback(id, imagesBlob[id]);
  } else {
    var storage = getStorage();
    getBlob(ref(storage, path)).then((blob) => {
      var reader = new FileReader();
      reader.onload = function(event){
        var base64 = event.target.result
        imagesBlob[id] = base64;
        callback(id, base64);
      };
      reader.readAsDataURL(blob);
    }).catch((_) => {
      callback(id, default_image);
    });
  }
}
