import React, { Component } from 'react';

import BaseLayout from '../components/BaseLayout'
import HeaderTitle from '../components/HeaderTitle'
import Table from '../components/Table'
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { getCocktailsByMachineMap, getAllCocktailsByMachineMap, updateCocktails } from '../utils/DBMachineManagement'
import { getCocktails } from '../utils/DBCocktailManagement'

class MachineCocktailsPage extends Component {

  constructor(){
    super();
    this.state = {
      cocktails: {},
      allCocktails: {},
      machineCocktails: {},
      loading: true
    }
  }

  componentDidMount() {    
    var machineId = this.props.location.state.machineId;
    getCocktails((cocktails) => {
      this.setState({ cocktails: cocktails });
      getCocktailsByMachineMap(machineId, (machineCocktails) => {
        getAllCocktailsByMachineMap(machineId, (allCocktails) => {
          // Saco los tragos que ya estan en la maquina
          var allCocktailsFiltered = {}
          for (const [key, value] of Object.entries(allCocktails)) {
            if (machineCocktails[key] === undefined) {
              allCocktailsFiltered[key] = value;
            }
          }
          this.setState({
            allCocktails: allCocktailsFiltered,
            machineCocktails: machineCocktails,
            loading: false
          });
        });
      });
    });
  }

  onClickButtonLeft = (id) => {
    var machineCocktails = this.state.machineCocktails;
    var allCocktails = this.state.allCocktails;
    var item = machineCocktails[id];
    delete machineCocktails[id];
    allCocktails[item.id] = item;
    this.updateDataBase(machineCocktails)
    this.setState({
      machineCocktails: machineCocktails,
      allCocktails: allCocktails
    });
  }

  onClickButtonRight = (id) => {
    var machineCocktails = this.state.machineCocktails;
    var allCocktails = this.state.allCocktails;
    var item = allCocktails[id];
    delete allCocktails[id];
    machineCocktails[item.id] = item;
    this.updateDataBase(machineCocktails)
    this.setState({
      machineCocktails: machineCocktails,
      allCocktails: allCocktails
    });
  }

  updateDataBase = (cocktails) => {
    var machineId = this.props.location.state.machineId;
    updateCocktails(machineId, cocktails, () => {})
  }

  renderAllCocktailsCard = () => {
    var items = [["Nombre", "Action"]];
    if (!this.state.loading) {
      for (const key of Object.keys(this.state.allCocktails)) {
        items.push( [
          this.state.cocktails[key].name, 
          <button onClick={() => this.onClickButtonRight(key)} class="button button-edit"><MdChevronRight style={{color: "#fff"}}/></button>
        ]);
      }
    }
    return (
      <div className="container-custom">
        <Table id="1" title="Todos los tragos" count_rows="2" width_sizes={ ['70%', '30%'] } items={ items } 
                loading={ this.state.loading } orderBy={1} align={['left', 'center']}/>
      </div>
    );
  }

  renderMachineCocktailsCard = () => {
    var items = [["Action", "Nombre"]];
    if (!this.state.loading) {
      for (const key of Object.keys(this.state.machineCocktails)) {
        items.push( [ 
          <button onClick={() => this.onClickButtonLeft(key)} class="button button-edit"><MdChevronLeft style={{color: "#fff"}}/></button>,
          this.state.cocktails[key].name, 
        ]);
      }
    }
    return (
      <div className="container-custom">
        <Table id="2" title="Tragos de la máquina" count_rows="2" width_sizes={ ['30%', '70%'] } items={ items } 
                loading={ this.state.loading } orderBy={1} align={['center', 'left']}/>
      </div>
    );
  }

	render() {
    return(
      <BaseLayout active_item_sidebar={4}>
          <div className="container-page">
            <HeaderTitle title="Dashboard" subtitle="Cocktails"/>
            <div className="container-custom">
              <div class="row">
                <div className="col-sm-12 col-md-6" style={{padding: 0}}>
                  { this.renderAllCocktailsCard() }
                </div>
                <div className="col-sm-12 col-md-6" style={{padding: 0}}>
                  { this.renderMachineCocktailsCard() }
                </div>
              </div>
            </div>
          </div>
      </BaseLayout>
    );
  }

}


export default MachineCocktailsPage;