import React, { Component } from 'react';
import { Bar } from "react-chartjs-2";

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
  );
  
class Graph extends Component {

	render() {
		return (
			<div className="row">
				<div className="col">
					<div className="mb-4 card card-small">
						<Bar
							data={{ 
								labels: this.props.cantBottles,
								datasets:
									[{
										label:'Cantidad(ml)',
										backgroundColor: this.props.colorBottles,
										borderColor: 'rgba(0,0,0,1)',
										borderWidth: 2,
										data: this.props.availableBottles
									}]
								}}
							options={{
								plugins: {
									legend: {
									display: false,
									},
									label:{
										display: false,
									},
									title: {
										display: true,
										font: {
											size: 16,
											family: 'Raleway'
										},
										text: 'Botellas cargadas',
										padding: {
											top: 20,
											bottom: 20
										}
									}
								}
							}}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default Graph;